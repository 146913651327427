import React from 'react';
class RatingEdit extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			data: {
				id: 0,
				comment: '',
				thumbsDown: false,
				thumbsUp: false,
				name: ''
			}
		}
	}
	componentDidUpdate(prevProps) {
		if(this.props.data && this.props.data != prevProps.data) {
			this.setState({data: this.props.data})
		}
	}
	handleEditQuestion = () => {
		if(this.props.data.id) {
			this.props.onEditQuestionRating(this.props.data.id);
		}
	}
	render() {
		var voteUp = (
			<i className="icon icon-thumbs-up">
				<span>&nbsp;{this.props.data.voteUp && this.props.data.voteUp.toString()}&nbsp;&nbsp;</span>
			</i>
		)
		var	voteDown = (
			<i className="icon icon-thumbs-down">
				<span>&nbsp;{this.props.data.voteDown &&this.props.data.voteDown.toString()}&nbsp;&nbsp;</span>
			</i>
		);
		let comment;
		if (this.props.data.userComment) {
			comment = this.props.data.userComment.map((comment, index) => <div key={index} id={index}>{comment}</div>)
		}
		return (
			<div className="card">
				<div className="card-body">
					<div className="form-group row">
						<label className="col-lg-3">{I('Subject')}</label>
						<div className="col-lg-8">
							{this.props.data.name}
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row">
						<label className="col-lg-3">{I('Rating')}</label>
						<div className="col-lg-8">
							{voteUp}
							{voteDown}
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row">
						<label className="col-lg-3">{I('Comment')}</label>
						<div className="col-lg-8">
							{comment}
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row editPageActionButton">
						<div className="col-lg-12 textAlignRight rating-button">
							<button className="btn-grey save-button" style = {{marginRight:"10px"}} type="button" onClick={this.props.onCancel}>{I('Cancel')}</button>
							<button className="btn-blue save-button" type="button" onClick={this.handleEditQuestion}>{I('Edit Question')}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default RatingEdit;
