import { connect } from 'react-redux';
import {
    TXT_DOWNLOADING_DATA
    , TXT_DOWNLOAD_FAIL
    , FORM_EMPTY
	, FORM_CREATEGROUP
	, FORM_EDIT
    , FORM_UPLOAD
    , M_GROUPS
} from '../../../common/v5/constants';
import {
    changeAdminView
    , toggleAdminEdit
    , toggleAdminFormType
    , setAdminColumnWidth
    , setAdminInput
    , resetAdminInput,
    adminActionStatus
} from '../../../redux/actions/admin';
//
import {
    getAdminListSelector
    , listColumnSelector
    , adminGroupsSelector
} from '../../../redux/selectors/admin';
import {
    // fetchAdminList,
    sstAsyncs
} from '../../../redux/actions/async/admin';
import { orgAreaMemo } from '../../../redux/selectors/server'
import {
    togglePopWaiting
    , clearPopWaiting
    , popErrorOnly
} from '../../../redux/actions/hmf'
import { onlyActiveAreasSelector } from '../../../redux/selectors/workflow'
import { pleaseWaitString } from '../../../common/v5/helpers';
import { TXT_FETCHING_DATA, TXT_DELETING } from '../../../common/v5/chatbotConstants'
import {
    getAdminGroups,
    getOneAdminGroups,
    postAdminGroups,
    deleteAdminGroups,
    getGroupFolder,
    getOneGroupFolder,
    postGroupFolder,
    deleteGroupFolder,
    getGroupAgents,
    getOneGroupAgents,
    postGroupAgents,
    deleteGroupAgents
} from '../../../redux/actions/async/ajax';
import { admin, groupFoldersSelector, groupAgentsSelector } from '../../../redux/selectors/admin';
import { async } from '../../../redux/util';
import {
    keyGetAdminGroups,
    keyGetOneAdminGroups,
    keySaveAdminGroups,
    keyDeleteAdminGroups,
    keyGetGroupFolders,
    keyGetOneGroupFolders,
    keySaveGroupFolders,
    keyDeleteGroupFolders,
    keyGetGroupAgents,
    keyGetOneGroupAgents,
    keySaveGroupAgents,
    keyDeleteGroupAgents
} from '../../../redux/constants/keys';

const mapState = (store, props) => {
    const server = store.server
        , app = store.app
        , admin = app.admin
        , ui = admin.ui
        , adminSt = admin.admin
        , wf = app.workflow
        ;
    return {
        view: ui.view,
        formType: adminSt.formType,
        folderList: groupFoldersSelector(store),
        groupsAgentList: groupAgentsSelector(store),
        list: adminGroupsSelector(store),
        langSrc: adminSt.langSrc,
        order: listColumnSelector(store),
        show: adminSt.show,
        showInline: adminSt.showInline,
        folderReady: adminSt.folderReady,
        folderListReady: adminSt.folderListReady,
        groupAgentListReady: adminSt.groupAgentListReady,
        showList: adminSt.showList,
        activeId: adminSt.activeId,
        areaList: onlyActiveAreasSelector(store),
        input: adminSt.input,
        columnWidth: adminSt.columnWidth,
        orgArea: orgAreaMemo(store),
        filter: adminSt.filter,
        adminStatus: adminSt.adminStatus,
    };
};

const getAdminGroupsList = () => async(getAdminGroups(),
    admin[keyGetAdminGroups]
);
const oneAdminGroups = (id) => async(getOneAdminGroups(id),
    admin[keyGetOneAdminGroups]
);
const saveAdminGroups = (p) => async(postAdminGroups(p),
    admin[keySaveAdminGroups]
);
const removeAdminGroups = (p) => async(deleteAdminGroups(p),
    admin[keyDeleteAdminGroups]
);
const getGroupFolderList = (p) => async(getGroupFolder(p),
    admin[keyGetGroupFolders]
);
const oneGroupFolderList = (p) => async(getOneGroupFolder(p),
    admin[keyGetOneGroupFolders]
);
const saveGroupFolderList = (p) => async(postGroupFolder(p),
    admin[keySaveGroupFolders]
);
const removeGroupFolderList = (p) => async(deleteGroupFolder(p),
    admin[keyDeleteGroupFolders]
);
const getGroupAgentList = (p) => async(getGroupAgents(p),
    admin[keyGetGroupAgents]
);
const oneGroupAgentList = (p) => async(getOneGroupAgents(p),
    admin[keyGetOneGroupAgents]
);
const saveGroupAgentList = (p) => async(postGroupAgents(p),
    admin[keySaveGroupAgents]
);
const removeGroupAgentList = (p) => async(deleteGroupAgents(p),
    admin[keyDeleteGroupAgents]
);
const groupsMethod = {
    list: () => getAdminGroupsList()
    , one: id => oneAdminGroups(id)
    , remove: removeAdminGroups
    , save: id => saveAdminGroups(id)
}
const groupFolderMethods = {
    list: (id) => getGroupFolderList(id)
    , one: (id) => oneGroupFolderList(id)
    , remove: removeGroupFolderList
    , save: (id) => saveGroupFolderList(id)
}
const groupAgentMethods = {
    list: (id) => getGroupAgentList(id)
    , one: (id) => oneGroupAgentList(id)
    , remove: removeGroupAgentList
    , save: (id) => saveGroupAgentList(id)
}

//redux-thunk
const fetchAdminList = (view, dispatcher) => (dispatch, getState) => {
    dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
    dispatch(dispatcher())
        .then((rs) => {
            dispatch(clearPopWaiting())
        })
        .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
                console.log("error load admin list:", { err, view });
            }
            dispatch(clearPopWaiting())
            return dispatch(popErrorOnly(err));
        })
}
const refreshForms = (view, params) => (dispatch, getState) => {
    dispatch(fetchAdminList(view, groupsMethod.list));
    dispatch(resetAdminInput());
    dispatch(groupsMethod.one({ id: params.gid }))
    dispatch(groupFolderMethods.list({ gid: params.gid }));
}

const mapDispatch = (dispatch, props) => {
    return {
        onLoad: (view, freshLoad) => {
            dispatch(changeAdminView(view));
            dispatch(fetchAdminList(view, groupsMethod.list));
        },
        onLoadToEdit: (id, view, popout, list) => {
            dispatch(resetAdminInput());
            dispatch(toggleAdminFormType(FORM_EMPTY));
            dispatch(togglePopWaiting(pleaseWaitString(TXT_FETCHING_DATA)));
            dispatch(groupsMethod.one({ id: id }))
                .then((rs) => {
                    dispatch(clearPopWaiting());
                    dispatch(toggleAdminEdit(id, true, false, true));
                    dispatch(toggleAdminFormType(FORM_EDIT));
                    dispatch(groupFolderMethods.list({ gid: id }));
                    dispatch(groupAgentMethods.list({ gid: id }));
                })
                .catch(err => {
                    console.log("error getting records:", err);
                    dispatch(clearPopWaiting());
                    return dispatch(popErrorOnly(err));
                })
        },
        onLoadToEditFolder: (id, view, popout, list) => {
            dispatch(groupFolderMethods.one({ id: id }))
                .catch(err => {
                    console.log("error getting folder:", err);
                    dispatch(clearPopWaiting());
                    return dispatch(popErrorOnly(err));
                })
        },
        onLoadToUpload: (id, view, popout, list) => {
			dispatch(toggleAdminFormType(FORM_UPLOAD));
			dispatch(resetAdminInput());
			dispatch(toggleAdminEdit(0, true, false, true));
		},
        onFetchAdminList: () => {
			dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
			dispatch(fetchAdminList(M_GROUPS))
			dispatch(toggleAdminEdit(0,false));
			dispatch(clearPopWaiting());
		},
        onToggleAdminFormType: (formType) => {
			dispatch(resetAdminInput());
			dispatch(toggleAdminFormType(formType));
		},
        onSetAdminColumnWidth: (width) => {
			dispatch(setAdminColumnWidth(width));
		},
        onChangeAdminInput: (field, value, key) => {
            dispatch(setAdminInput(field, value, key));
        },
        onSaveAdmin: (view, params) => {
            dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
            dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
            // dispatch(sstAsyncs[view].save(params))
            dispatch(groupsMethod.save(params))
                .then((rs) => {
                    dispatch(clearPopWaiting())
                    .then((rs) => {
                        dispatch(fetchAdminList(view, groupsMethod.list));
                    })
                    dispatch(resetAdminInput());
                    dispatch(toggleAdminEdit(0, false, false, true));
                    dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
                })
                .catch(err => {
                    console.log("error saving :", err)
                    dispatch(clearPopWaiting());
                    dispatch(adminActionStatus({ status: 3, msg: err }));
                    return dispatch(popErrorOnly(err));
                });
        },
        onSaveGroupFolder: (view, params) => {
            dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
            dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
            dispatch(groupFolderMethods.save(params))
                .then((rs) => {
                    dispatch(clearPopWaiting())
                    .then((rs) => {
                        dispatch(refreshForms(view, params))
                    })
                    dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
                })
                .catch(err => {
                    console.log("error saving folder:", err)
                    dispatch(clearPopWaiting());
                    dispatch(adminActionStatus({ status: 3, msg: err }));
                    return dispatch(popErrorOnly(err));
                });
        },
        onSaveGroupAgent: (view, params) => {
            dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
            dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
            dispatch(groupAgentMethods.save(params))
                .then((rs) => {
                    dispatch(clearPopWaiting())
                    .then((rs) => {
                        dispatch(fetchAdminList(view, groupsMethod.list));
                        dispatch(toggleAdminEdit(0, false, false, true));
                        dispatch(toggleAdminFormType(FORM_EMPTY));
                        // dispatch(resetAdminInput());
                        // dispatch(toggleAdminEdit(0, false, false, true));
                        // dispatch(toggleAdminFormType(FORM_EMPTY));
                        dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
                    })
                })
                .catch(err => {
                    console.log("error saving agents:", err)
                    dispatch(clearPopWaiting());
                    dispatch(adminActionStatus({ status: 3, msg: err }));
                    return dispatch(popErrorOnly(err));
                });
        },
        onDeleteFromList: (id, view, popout, list) => {
            dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
            dispatch(removeAdminGroups(id))
                .then((rs) => {
                    dispatch(fetchAdminList(view, groupsMethod.list));
                    //close form
                    dispatch(toggleAdminEdit(0, false, false, true));
                    dispatch(clearPopWaiting());
                })
                .catch(err => {
                    console.log("error deleting :", err)
                    dispatch(clearPopWaiting());
                    return dispatch(popErrorOnly(err));
                });
        },
        onDeleteFolderFromList: (params, view, popout, list) => {
            dispatch(togglePopWaiting(pleaseWaitString(TXT_DELETING)));
            dispatch(removeGroupFolderList({ id: params.id, gid: params.gid }))
                .then((rs) => {
                    dispatch(clearPopWaiting())
                    .then((rs) => {
                        dispatch(refreshForms(view, params))
                    })
                })
                .catch(err => {
                    console.log("error deleting folder:", err)
                    dispatch(clearPopWaiting());
                    return dispatch(popErrorOnly(err));
                });
        },
        onCloseForm: (id, view, popout, list) => {
            dispatch(resetAdminInput());
            dispatch(toggleAdminEdit(0, false, false, true));
            dispatch(toggleAdminFormType(FORM_EMPTY));
        }
    }
};

const withGroups = connect(mapState, mapDispatch)

export default withGroups;
