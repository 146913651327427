import React, {
    useEffect
    , useState
    , useReducer
} from 'react';
import { I } from '../../../common/v5/config';
import classNames from 'classnames';
import { BTN_TXT_SAVE, BTN_TXT_SAVING } from '../../../common/v5/constants';
import {
    TableIconicButton as Button
} from '../../../reactcomponents/Form';
import { TableHeader } from './groups';
import { ModalBox } from '../../../reactcomponents/Modal';
import KanbanBoard from './draggables'
import Helper from '../../../reactcomponents/Helper';
import FileUploader from '../../../components/FileUploader';

const helperForGroupAgent = I("Drag and drop agents in the desired box");

const uploadAgentHelperTxt = <div>
    <div>{I("The csv file should contain group name, area name, system group name separated by a comma, for example:")}</div>
    <br />
    <div>{I("Group name,Area name,Systemgroup name")}</div>
    <br />
    <div>{"Support,General Support,Cention"}</div>
</div>

// Handles items reorder.
const reducer = (state, { source, destination, agents, type }) => {
    switch (type) {
        case "MOVE_SINGLE":
            //shallow copy source list
            const newSource = [...state[source.droppableId].list];
            //the single item
            const [itemData] = newSource.splice(source.index, 1); //todo: here need at multi

            // If the item has been dragged inside the same group.
            // This means source and destination is same
            if (source.droppableId === destination.droppableId) {
                //then rearrange by replacing respective index
                newSource.splice(destination.index, 0, itemData);
                return {
                    ...state,
                    [source.droppableId]: {
                        ...state[source.droppableId],
                        list: newSource
                    }
                };
            }

            // If the item has been dragged to a new group.

            //shallow copy destination list
            const newDestination = [...state[destination.droppableId].list];
            newDestination.splice(destination.index, 0, itemData);
            return {
                ...state,
                [source.droppableId]: {
                    ...state[source.droppableId],
                    list: newSource
                },
                [destination.droppableId]: {
                    ...state[destination.droppableId],
                    list: newDestination
                }
            };
        case "MOVE_MULTI":
            const sourceList = [...state[source.droppableId].list]; //array
            const [draggedItem] = sourceList.splice(source.index, 1)
            const newDestinationList = [...state[destination.droppableId].list];

            newDestinationList.splice(destination.index, 0, ...agents);
            //sometimes when click but drag different unclicked element, it may add duplicate of it, so we make it unique here
            const finalList = [...new Set(newDestinationList)];

            const newSourceList = sourceList.filter(item => !agents.some(agentItem => agentItem.id === item.id))
            return {
                ...state,
                [source.droppableId]: {
                    ...state[source.droppableId],
                    list: newSourceList
                },
                [destination.droppableId]: {
                    ...state[destination.droppableId],
                    list: finalList
                }
            };
        // return state
        default:
            throw new Error();
    }
};

const AgentUploadForm = ({ handleSubmit, input, handleChangeData, hidden, onDelete, onCancel, spinner }) => {

    const [status, setStatus] = useState(I("Create group - agent connection by uploading csv file."))

    const handleFileUpload = (rs) => {
        if (rs.status) {
            setStatus(I("Data successfully imported."))
        } else {
            setStatus(I("File uploading failed! Please contact support."))
        }
    }

    return (
        <div id="groupAgentsUpload">
            <div className='upload-input-wrap'>
                {/* <h3 className='title mb-2'>{I("Upload file")}</h3> */}
                <div className='pull-right upload'>
                    <FileUploader
                        className="btn-file-upload-wrap"
                        uploadTo="group/connection/upload"
                        multipleFile={false}
                        // onProgress={this.handleUploadProgress}
                        onFileupload={handleFileUpload}
                    />
                    <Helper>{uploadAgentHelperTxt}</Helper>
                </div>
            </div>
            <span className='label'>{status}</span>
        </div>
    )
}
const AgentForm = ({ data, activeId, view, onSaveGroupAgent, onCancel, onUpdateAgents, onUpdateTeamLeaders, adminStatus }) => {

    const [state, dispatch] = useReducer(reducer, data);
    const [checkedItems, setCheckedItems] = useState([]);
    const [edited, setEdited] = useState(false);
    const [totalSelected, setTotalSelected] = useState(0);
    const [selectedBoard, setSelectedBoard] = useState(null);
    const [popup, setPopup] = useState(false);

    const hasItemSelected = checkedItems.length !== 0;

    const onDragStart = ({ source, destination, ...args }) => {
        const sourceList = [...state[source.droppableId].list];
        const [draggedItem] = sourceList.splice(source.index, 1);

        //we track no. of selected item when start dragging (for multiselect)
        let currentSelected = [...checkedItems]

        //incase user dragged unchecked item alongside, then include it
        if (!currentSelected.includes(draggedItem)) {
            currentSelected.push(draggedItem);
            setCheckedItems(currentSelected)
            setSelectedBoard(source.droppableId)
        }
        setTotalSelected(currentSelected.length);
    }

    const onDragEnd = ({ source, destination, ...args }) => {
        // Ignore if the drop is outside a valid destination.
        if (!destination) return;

        // Ignore if the drop is in the original position.
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        )
            return;
        if (checkedItems.length >= 1) {
            dispatch({
                type: "MOVE_MULTI",
                source,
                destination,
                agents: checkedItems
            })
        } else {
            // Move item.
            dispatch({
                type: "MOVE_SINGLE",
                source,
                destination
            });
        }
        if (!edited) {
            setEdited(true);
        }
        //reset back
        setCheckedItems([]);
        setSelectedBoard(null)
        setTotalSelected(0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let members = state.agents.list.map(item => item.id.toString()).join(",");
        let teamLeaders = state.teamLeaders.list.map(item => item.id.toString()).join(",");

        let param = {
            id: activeId,
            fromGroup: true,
            memberList: members,
            teamLeaderList: teamLeaders
        };
        onSaveGroupAgent(view, param);
    }

    const handleItemClick = (id, column) => {
        const selectedItem = state[column].list.find(item => item.id === id)
        //if exist
        if (checkedItems.some(item => item.id === id)) {
            //then remove it
            const filtered = checkedItems.filter(agent => agent.id !== id);
            setCheckedItems(filtered);
            //if no more, then reset board
            if (filtered.length === 0) {
                setSelectedBoard(null)
                return;
            }
        } else {
            setCheckedItems(prevState => [...prevState, selectedItem])
            // setSelectedBoard(column)
        }
        if (!selectedBoard) {
            setSelectedBoard(column)
        }
    }

    const handleClear = () => {
        setCheckedItems([]);
        setSelectedBoard(null);
        setTotalSelected(0);
    }

    const handleUploadAgents = () => {
        setPopup(true);
    }

    useEffect(() => {
        let members = state.agents.list.map(item => item.id.toString()).join(",");
        onUpdateAgents(members);

        let teamLeaders = state.teamLeaders.list.map(item => item.id.toString()).join(",");
        onUpdateTeamLeaders(teamLeaders);
    }, [state])
    return (
        <div className="group-agent-edit">
            <TableHeader
                title={I("Agents in the group")}
                helperText={helperForGroupAgent}
            >
                <Button
                    type='button'
                    className={classNames("btn bg-transparent btn-round", { "active": false })}
                    iconClass='icon-add'
                    title={I('Upload New')}
                    onClick={handleUploadAgents}
                    hide={true}
                />
            </TableHeader>
            {popup &&
                <ModalBox
                    show={popup}
                    onClose={() => setPopup(false)}
                    className={"agent-upload"}
                    headerContent={<h3>{I("Upload file")}</h3>}
                >
                    <AgentUploadForm />
                </ModalBox>}
            <KanbanBoard
                data={state}
                checkBox={true}
                checkedItems={checkedItems}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onItemClick={handleItemClick}
                selectedBoard={selectedBoard}
                hasItemSelected={hasItemSelected}
                totalSelected={totalSelected}
                itemField={"username"}
            />
            <div className='board-actions'>
                <Button hide={!hasItemSelected} type="button" onClick={handleClear} className="btn-white clear-selection" title="Clear" data-qa-id="btn-clear-ticks" label={I("Clear selection")} />
            </div>
        </div>
    )
}

const AgentSection = ({onGetAgents, onGetTeamLeaders,...props}) => {
    if (!props.groupAgentListReady) {
        return <p>Loading...</p>
    }

    let list = props.groupsAgentList;
    let data = {};

    if (Object.keys(list).length > 0) {
        data = {
            available: {
                "title": I("All Agents"),
                "list": list.available
            },
            agents: {
                "title": I("Added Agents"),
                "list": list.members
            },
            teamLeaders: {
                "title": I("Added Team leaders"),
                "list": list.teamLeaders
            }
        }
    }

    return (
        <AgentForm data={data} onUpdateAgents={onGetAgents} onUpdateTeamLeaders={onGetTeamLeaders} adminStatus={props.adminStatus} {...props} />
    )
}

export default AgentSection;
