import { connect } from 'react-redux';
import { push } from '../../common/v5/utils';
import {
	selectToggleSideBar,
	selectCollapseSideBar,
	setSelectedAgent,
	setMobileView,
	setErrandMobileView,
	resetWorkflowView
} from '../../redux/actions/workflow';

import {
	manualButtonsSelector,
	IMRecipientsSelector,
	getSelectedIds,
	getTotalSelectedMessages
} from '../../redux/selectors/imrds';

import {
	MESSAGE_INVALID_AGENT,
	MESSAGE_INVALID_AGENT_GROUP,
	RC_IM,
	RC_GROUP,
	RPLY_MANUAL_IM,
	emptyArray
} from '../../common/v5/constants';

import InternalMessagesList from './internalMsgList';

import {
	IMOnLoad,
	stopIMCounters,
	fetchInternalMessages,
	openSingleIM,
	loadList,
	fetchAgentBook,
	fetchGroupBook,
	getAgentInfo,
	getGroupInfo,
	submitIM,
	doDeleteMessage,
	doRestoreMessage,
	saveIM,
	NewIMOnLoad,
	setIMSelected,
	closeMessageView
} from '../../redux/actions/async/internalMessages';

import {
	changeAdminView,
} from '../../redux/actions/admin';

import {
	filterMessageList,
	selectFolder,
	selectOrToggleSort,
	selectMessageFromList,
	selectAllMessages,
	setCurrentIM,
	changeMessageListSelection,
	inputTextChanges,
	setIMReplyAddressType,
	showAgentBook,
	showGroupBook,
	toggleIMPopup,
	appendReplyAgentAddress,
	appendReplyGroupAddress,
	setIMSortParams,
	IMRecipientsChange,
	IMGroupsChange,
	toggleShowIMReply,
	changeAgentListSelection,
	updateSelectedAgent,
	changeGroupListSelection,
	updateSelectedGroup,
	isNewThread
} from '../../redux/actions/internalMessages';

import {
	togglePopAlert
} from '../../redux/actions/hmf';

import {
	NewIM
} from '../../components/v5/NewIM';

import {
	AgentBook
} from '../../components/v5/AgentBook';

import {
	GroupBook
} from '../../components/v5/GroupBook';

const mapNewIM = (state, props) => {
	const im = state.app.internalMessage, m = im.ui.manual, inpt = im.manualInputs, wf = state.app.workflow,
		s = {
			wfSettings: wf.fetchWfSettings.data,
			createdId: m.createdId,
			state: m.state,
			show: im.ui.showIMPopAddMessage,
			agentList: wf.agents.data, //im.agents.data,
			agentGroupList: wf.agentGroups.data,
			content: inpt.update_answer,
			subject: inpt.update_subject,
			recipients: IMRecipientsSelector(state, props),
			recipientOptions: emptyArray,
			buttonsState: manualButtonsSelector(state, props),
			lastSavedTimestamp: '',
			newIM: im.ui.isNewIM.newIM,
			answer: inpt.update_answer,
			plain: inpt.plain_answer,
			selectedAgent: im.currentMessage.agentCard.agent.name,
			selectedGroup: im.currentMessage.groupCard.group.name,
			replyTypeAgent: im.ui.agentBook.replyType,
			replyTypeGroup: im.ui.groupBook.replyType,
			isShowIM: im.ui.showIMPopAddMessage
		};
	return s;
};

const mapNewIMCallbacks = (dispatch, props) => {
	return {
		onLoad: () => {
			// generate redux state for agent/group selection
			return dispatch(NewIMOnLoad());
		},
		onSubjectChange: e => {
			dispatch(inputTextChanges('update_subject', e.target.value,
			RPLY_MANUAL_IM));
		},
		onClose: value => {
			dispatch(toggleIMPopup(value)); //lwb
			dispatch(setIMReplyAddressType("to", "reply", "agentBook"));   //for reply inputs state
			dispatch(setIMReplyAddressType("group", "reply", "groupBook"));   //for reply inputs state
			dispatch(isNewThread(false))
		},
		onContentChange: (html, plain) => {
			dispatch(inputTextChanges('update_answer', html, plain,
			RPLY_MANUAL_IM));
		},
		onRecipientsChange: (which, recipients, agents) => {
			dispatch(IMRecipientsChange(which, recipients, RPLY_MANUAL_IM, agents));
		},
		onGroupsChange: (which, recipients, groups) => {
			dispatch(IMGroupsChange(which, recipients, RPLY_MANUAL_IM, groups));
		},
		onFailValidation: (type, input) => {
			if (type === RC_IM) {
				dispatch(togglePopAlert(MESSAGE_INVALID_AGENT + ': ' + input));
			} else if (type === RC_GROUP) {
					dispatch(togglePopAlert(MESSAGE_INVALID_AGENT_GROUP + ': ' + input));
			} else {
				dispatch(togglePopAlert('invalid input: '+input));
			}
		},
		onClear: e => {
			dispatch(inputTextChanges('update_answer', '', RPLY_MANUAL_IM));
		},
		onSubmit: (type, createType) => {
			dispatch(submitIM(type, createType));
		},
		onSave: type => {
			dispatch(saveIM(type));
			dispatch(fetchInternalMessages(4))
		},
		onToggleAgentBook: (tgl, replyType, selected, agents) => {
			dispatch(showAgentBook(tgl));
			if (tgl) {
				dispatch(updateSelectedAgent(selected, agents));
			}
			dispatch(setIMReplyAddressType(replyType, "manual", "agentBook"));
			dispatch(fetchAgentBook(""));
		},
		onToggleGroupBook: (tgl, replyType, selected, agentGroups) => {
			dispatch(showGroupBook(tgl));
			if (tgl) {
				dispatch(updateSelectedGroup(selected, agentGroups));
			}
			dispatch(setIMReplyAddressType(replyType, "manual", "groupBook"));
			dispatch(fetchGroupBook(""));
		},
	};
};

export const NewIMModalCtnr = connect(mapNewIM,	mapNewIMCallbacks)(NewIM);

const mapStateToProp = (state, props) => {
	//console.log('State=>>>', state);
	let im = state.app.internalMessage,
		wf = state.app.workflow,
		s = {
			wfs: wf.fetchWfSettings.data,
			isFetching: im.ui.isFetching,
			loadingMessageList: im.messageList.wip,
			wfui: wf.ui,
			listInputs: wf.listInputs,
			filter: im.ui.filter,
			messagelist: im.messageList.data,
			showIMPopAddMessage: im.ui.showIMPopAddMessage,
			// agents: im.agents,
			// agentAreas: im.agentAreas,
			counters: im.counters,
			selectedMessages: getSelectedIds(im.messageList.data),
			ui: im.ui,
			currentMessageID: im.currentMessage.id,
			activateChat: state.app.footer.uiData.activateChatWindow,
			totalSelected: getTotalSelectedMessages(im.messageList.data ? im.messageList.data : []),
		}
	return s;
};
const mapIMCallbacks = dispatch => {
	return {
		onLoad: () => {
			// dispatch(agentSetErrandView("Conversation"));
			return dispatch(IMOnLoad());
		},
		onUnload: () => {
			//below code is trick for direct notification to message, else the direction sometime will not work
			// dispatch(selectFolder(3));
			// dispatch(filterMessageList("Drafts"));
			// dispatch(fetchInternalMessages(3));
			// dispatch(setCurrentIM(0));
			//code end for notification direction
			return dispatch(stopIMCounters());
		},
		filterMessagesList: (f) => {
			dispatch(filterMessageList(f));
		},
		filterFolder: (id) => {
			dispatch(fetchInternalMessages(id));
			dispatch(selectFolder(id));
			dispatch(setCurrentIM(0));
			dispatch(selectAllMessages(false));
			dispatch(changeMessageListSelection(0, true));
			if (id == 1 ) {
				dispatch(toggleShowIMReply(true))
			} else {
				dispatch(toggleShowIMReply(false))
			}
		},
		onToggleShowSort: toggleState => {
			dispatch(selectOrToggleSort(toggleState));
		},
		onToggleMessageListSort: (value, index) => {
			dispatch(selectOrToggleSort(value, index));
		},
		onOpenCurrentMessage: (id) => {
			dispatch(openSingleIM(id));
		},
		onSelectMessageFromList: (id, select) => {
			dispatch(selectMessageFromList(id, select));
		},
		onSelectAllMessages: toggleState => {
			dispatch(selectAllMessages(toggleState));
			dispatch(setCurrentIM(0));
		},
		onToggleSideBar: (toggle) => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onSetSelectedAgent: (id) => {
			dispatch(setSelectedAgent(id));
		},
		onSetMobileView: (toggle) => {
			dispatch(setMobileView(toggle));
		},
		onSetActivateErrandMobileView: (toggle) => {
			dispatch(setErrandMobileView(toggle));
		},
		setLoadList: () => {
			dispatch(loadList("setLoadList"));
		},
		setParams: (p) => {
			dispatch(setIMSortParams(p));
		},
		onToggleShowManualIM: (value, edit, did) => {
			dispatch(toggleIMPopup(value, edit, did));
		},
		onDeleteMessages: (ids, folderId) => {
			dispatch(doDeleteMessage(ids, true));
		},
		onChangeAdminView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onRestoreMessages: (ids, folderId) => {
			dispatch(doRestoreMessage(ids, true));
		},
		onCloseMessageView: () => {
			dispatch(closeMessageView());
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
	};
};

const InternalMsgListCtnr = connect(mapStateToProp, mapIMCallbacks)(InternalMessagesList);

export default InternalMsgListCtnr;


/* Agent Book is agent explorer */
const mapAgentBook = (state, props) => {
	const im = state.app.internalMessage, ui = im.ui, ab = ui.agentBook,
			cim = im.currentMessage,  ca = cim.agentCard,
	    s = {
			show: ab.show,
			replyType: ab.replyType,
			agentBook: im.currentMessage.agentBook,
			agentList: ca.agentList,
			agent: ca.agent
		};
	return s;
};

const mapAgentBookCallbacks = (dispatch, props) => {
	return {
		onToggle: (toggle) => {
			dispatch(showAgentBook(toggle));
		},
		onShowAgents: id => {
			dispatch(getAgentInfo(id));
		},
		onHandleSearch: (toggle, search) => {
			dispatch(showAgentBook(toggle));
			dispatch(fetchAgentBook(search));
		},
		onReplyAdddress: () => {
			dispatch(appendReplyAgentAddress());
		},
		onSelect: (id, select) => {
			dispatch(changeAgentListSelection(id, select));
			// dispatch(selectMessageFromList(id, select));
			// this.props.onSelectMessageFromList(id, select);
		}
	};
};

export const AgentBookModalCtnr = connect(mapAgentBook,
	mapAgentBookCallbacks)(AgentBook);


/* Agent Group is Group explorer */
const mapGroupBook = (state, props) => {
	const im = state.app.internalMessage, ui = im.ui, ab = ui.groupBook,
			cim = im.currentMessage,  cg = cim.groupCard,
	    s = {
			show: ab.show,
			replyType: ab.replyType,
			// groupList: im.currentMessage.groupBook,
			groupBook: im.currentMessage.groupBook,
			groupList: cg.groupList,
			group: cg.group
		};
	return s;
};

const mapGroupBookCallbacks = (dispatch, props) => {
	return {
		onToggle: (toggle) => {
			dispatch(showGroupBook(toggle));
		},
		onShowGroups: id => {
			dispatch(getGroupInfo(id));
		},
		onHandleSearch: (toggle, search) => {
			dispatch(showGroupBook(toggle));
			dispatch(fetchGroupBook(search));
		},
		onReplyAdddress: () => {
			dispatch(appendReplyGroupAddress());
		},
		onSelect: (id, select) => {
			dispatch(changeGroupListSelection(id, select));
		}
	};
};

export const GroupBookModalCtnr = connect(mapGroupBook,
	mapGroupBookCallbacks)(GroupBook);
