import React from 'react';
import classNames from 'classnames';
import DropDown from '../../../components/DropDown';
import { I } from '../../../common/v5/config';
import { MM_SEARCHANSWERS, CENTION_LOGO_MINI, CENTION_LOGO } from '../../../common/v5/constants';
import MenuCtnr from '../../../common/v5/menuCtnr';
import { isValidTelavoxUrl } from '../../../common/v5/helpers';
import { SidebarSettingsCtnr } from '../workflowCtnr';
import ToggleSideBar from '../../../components/v5/ToggleSideBar';
import KnowledgeBaseTree from './knowledgeBaseTree';
import { WorkflowBreadcrumbsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr } from '../workflowCtnr';
import { CancellableSimpleLink } from '../../../containers/link';
import { isMobile } from '../../../redux/util';
import { CheckboxBase } from '../../../reactcomponents/SquareCheckbox'

const EITHER = "either"
    , BOTH = "both"
    , QUESTION = "question"
    , ANSWER = "answer";

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    };
    handleInputChange = (e) => {
        this.props.onChangeInput(e.target.value);
    }
    handleCheckBoxChange = (e) => {
        const name = e.target.name;
        this.props.onCheckBoxChange(name)
    };
    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.onSearch(true);
        }
    };
    render() {
        const { searchSource, searchState } = this.props;
        const searchBlockStyle = {
            marginBottom: '20px'
        }
        const checkboxContainerStyle = {
            display: 'flex'
            , paddingTop: '8px'
        };
        const checkboxStyle = {
            display: 'flex'
            , paddingLeft: '5px'
            , paddingRight: '5px'
        };
        const labelStyle = {
            color: '#6d6d6d'
            , fontSize: '11px'
            , fontWeight: '300'
            , lineHeight: '12px'
            , paddingLeft: '2px'
        };
        return (
            <div className="search-block-page" style={searchBlockStyle}>
                <div className="search-form" style={{ display: 'flex' }}>
                    <input
                        data-qa-id={"search-answers-text-input"}
                        id="search_answer"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                        placeholder={I("Search for...")}
                        type="text"
                    />
                    <i className="fas fa-search" hidden={searchState}/>
                    <div className="loader" hidden={!searchState} />
                </div>
                <div className="search-filters" style={checkboxContainerStyle}>
                    <CheckboxBase
                        checked={searchSource === EITHER}
                        qaId={"search-answers-checkbox-1"}
                        name={EITHER}
                        label={I('Either Question or Answer')}
                        onChange={this.handleCheckBoxChange}
                    />
                    <CheckboxBase
                        checked={searchSource === BOTH}
                        qaId={"search-answers-checkbox-2"}
                        name={BOTH}
                        label={I('Both Question and Answer')}
                        onChange={this.handleCheckBoxChange}
                    />
                    <CheckboxBase
                        checked={searchSource === QUESTION}
                        qaId={"search-answers-checkbox-3"}
                        name={QUESTION}
                        label={I('Question Only')}
                        onChange={this.handleCheckBoxChange}
                    />
                    <CheckboxBase
                        checked={searchSource === ANSWER}
                        qaId={"search-answers-checkbox-4"}
                        name={ANSWER}
                        label={I(' Answer Only')}
                        onChange={this.handleCheckBoxChange}
                    />
                </div>
            </div>
        )
    };
};

class SearchAnswers extends React.Component {
    constructor(props) {
        super(props);
        this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
        this.handleSideBarCollapse = this.handleSideBarCollapse.bind(this);
		this.handleMessageEvent = this.handleMessageEvent.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            selectedLibrary: 0
            , libraries: []
            , hideWarning: true
        }
    };
    componentDidMount = () => {
        this.props.onLoadKnowledgeBaseList();
        window.addEventListener('resize', this.handlePgResize);
		window.addEventListener('message', this.handleMessageEvent);
    };

    componenetWillUnmount = ()=> {
        window.removeEventListener('resize', this.handlePgResize);
		window.removeEventListener('message', this.handleMessageEvent);
    }

    handleLibraryChange = (id) => {
        this.setState({ selectedLibrary: id });
        if(!this.state.hideWarning) {
            this.setState({ hideWarning: true })
        }
    };
    handleSearch = (id) => {
        const { hideWarning, selectedLibrary } = this.state;
        if (selectedLibrary === 0) {
            this.setState({ hideWarning: false });
        } else {
            if (!hideWarning) {
                this.setState({ hideWarning: true });
            };
            this.props.onSearch(id);
        };
    };
    handleSideBarToggle() {
        this.props.onToggleSideBar(!this.props.ui.showSideBar)
    };
    handleSideBarCollapse() {
        this.props.onCollapseSideBar(!this.props.ui.collapseSideBar);
    }
    handlePgResize = () => {
		if(isMobile) {
			this.props.onSetMobileView(true);
		}else {
			this.props.onSetMobileView(false);
		}
	}
    handleHomePage = (e) => {
        e.preventDefault();
		this.props.onMainView();
    }
	handleMessageEvent(e) {
		if(typeof e.data === "object"){
			if(typeof e.data.action !== 'undefined' &&
				e.data.action !== null){
				if(e.data.action == "open-errand"){
					let urlValid = isValidTelavoxUrl(e.origin);
					if(typeof e.data.params !== 'undefined' &&
						e.data.params !== null && urlValid == true){
						externalqueue.isPostMessage = true;
						if(typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== ""){
								let encoded = e.data.params.deepLink;
								this.props.simpleLoadAndOpen(encoded, 0);
						}
					}
				} else {
					console.info("unhandled messsage action:", e.data.action);
				}
			}
		}
	}
    render() {
        const {
            errandKbList
            , onChangeInput
            , onCheckBoxChange
            , onFetchKnowledgeBase
            , onChangeView
            , searchSource
            , searchState
            , searchValue
            , treeList
            , ui
            , edit
        } = this.props;
        let layoutClass = 'layout slim'
            , libraries = [];
        if (!ui.showSideBar) {
            layoutClass = 'layout slim closed-sidebar'
        };
        if (errandKbList != undefined || null) {
            libraries = errandKbList.list
        };
        const warningStyle = {
            padding: '3px'
            , backgroundColor: 'rgb(255, 114, 114)'
            , color: 'white'
            , marginBottom: '2px'
            , width: 'fit-content'
            , borderRadius: '3px'
            , fontSize: '10.8px'
        }
        let sidebarClass = "", logoWrapperStyle = {};
        if(ui.collapseSideBar){
            sidebarClass = "collapsed";
            logoWrapperStyle = {margin: "auto", textAlign: "center"};
        }
        let flexMenusStyle = {padding: "15px 10px"};
		if(ui.collapseSideBar) {
			flexMenusStyle = {padding: "0px"};
		}
        const hideLogo = features['hide-cention-logo'];
        let customStyledAppHeader = { borderRadius: "initial"}
        if(hideLogo && ui.collapseSideBar) {
            customStyledAppHeader = { borderRadius: "initial", padding: "0px 15px"}
        }
        return (
            <div id="page-wrapper" className="search-answer-kb">
                <section className={layoutClass} data-app-layout>
                    <div className={classNames("app-sidebar", sidebarClass)}>
                        <div className="app-header search-answers-menu-header" style={customStyledAppHeader}>
                            {
                                (hideLogo) &&
                                    <MenuCtnr visible={!ui.showErrandMobileView && !ui.collapseSideBar} />
                            }
                            {
                                (hideLogo) &&
                                    <ToggleSideBar
                                    visible={ui.showSideBar || ui.showMobileView}
                                    collapsed={ui.collapseSideBar}
                                    onCollapse={this.handleSideBarCollapse}
                                    mobileMode={ui.showMobileView}
                                    onClick={this.handleSideBarToggle} />
                            }
                            <div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
                                <CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
                                    <img src={ui.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt=""/>
                                </CancellableSimpleLink>
                            </div>
                        </div>
                        <WorkflowInnerContainerCtnr className="app-inner-content">
                            <div className="flex-menus-wrap" style={flexMenusStyle}>
                                <div className="side-bar-main-menu" hidden={hideLogo}>
                                    {
                                        !ui.collapseSideBar &&
                                            <div style={{marginRight: "24px"}}><MenuCtnr visible={!ui.showErrandMobileView} /></div>
                                    }
                                    <ToggleSideBar visible={ui.showSideBar || ui.showMobileView}
                                        showSideBar={ui.showSideBar}
                                        collapsed={ui.collapseSideBar}
                                        mobileMode={ui.showMobileView}
                                        id={"QA_toggle_sidebar_top"}
                                        onCollapse={this.handleSideBarCollapse}
                                        onClick={this.handleSideBarToggle} />
                                </div>
                                {
                                    ui.collapseSideBar &&
                                    <IconizeSideBarMenuCtnr onChangePage={onChangeView} currentPage={MM_SEARCHANSWERS} showWorkflowMenu={true} />
                                }
                            </div>
                            <SidebarSettingsCtnr />
                        </WorkflowInnerContainerCtnr>
                    </div>
                    <div className="app-content search-answers">
                        <div className="app-header" style={customStyledAppHeader}>
                            <ul className="conversations-nav">
                                <ToggleSideBar
                                    collapsed={ui.collapseSideBar}
                                    onCollapse={this.handleSideBarCollapse}
                                    data-qa-id={"toggle-sidebar-search-answers"}
                                    onClick={this.handleSideBarToggle}
                                    visible={!ui.showSideBar || ui.collapseSideBar}
                                    wrapped={true} />
                            </ul>
                            {
                                (ui.collapseSideBar || !ui.showSideBar) &&
                                    <WorkflowBreadcrumbsCtnr />
                            }
                        </div>
                        <WorkflowInnerContainerCtnr className="app-inner-content">
                            <SearchBox
                                onChangeInput={onChangeInput}
                                onCheckBoxChange={onCheckBoxChange}
                                onSearch={this.handleSearch}
                                searchSource={searchSource}
                                searchState={searchState}
                            />
                            <span style={warningStyle} hidden={this.state.hideWarning}>
                                {I("Please choose a library...")}
                            </span>
                            <DropDown
                                fields={{ id: 'id', name: 'name' }}
                                id="SearchAnswersLibrary"
                                items={libraries}
                                onChange={this.handleLibraryChange}
                                selectedItems={this.state.selectedLibrary}
                            />
                            <KnowledgeBaseTree
                                library={this.state.selectedLibrary}
                                list={treeList}
                                onFetchKnowledgeBase={onFetchKnowledgeBase}
                                previewMaxHeight={350}
                                search={searchValue}
                                searchSource={searchSource}
                                searchState={searchState}
                                mobile={ui.showMobileView}
                                edit={edit}
                            />
                        </WorkflowInnerContainerCtnr>
                    </div>
                </section>
            </div>
        )
    };
};

export default SearchAnswers;
