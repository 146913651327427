import React from 'react';
import { SaveButton, CancelButton } from '../../../../reactcomponents/Admin';
import SwitchCheckbox from '../../../../reactcomponents/SwitchCheckbox';
import Helper from '../../../../reactcomponents/Helper';
class KnowledgeBaseEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			warningText: "",
			data: {
				id: 0,
				name: '',
				external: false
			}
		}
	}
	componentDidUpdate(prevProps) {
		console.log(this.props.data);
		console.log(prevProps.data);
		if (this.props.data != prevProps.data) {
			this.setState({ data: this.props.data })
		}
	}
	handleChange = (event) => {
		var state = this.state;
		if (event.target.type == 'text') {
			state.warningText = "";
			state.data[event.target.name] = event.target.value;
		} else if (event.target.type == 'checkbox') {
			state.data[event.target.name] = event.target.checked;
		}
		this.setState(state);
	}

	handleToggle = (checked) => {
		var state = this.state;
		state.data.external = !checked;
		this.setState(state);
	}
	handleSave = () => {
		if (this.state.data["name"] == "") {
			this.setState({ warningText: I("* You cannot leave the Name field empty.") });
			return
		}
		if (this.props.handleSave != undefined) {
			this.props.handleSave(this.state.data);
		}
	}
	render() {
		var libName = " ";
		var checkState = false;
		if (this.state.data && typeof (this.state.data.name) !== 'undefined') {
			libName = this.state.data.name;
		}
		if (this.state.data && typeof (this.state.data.external) !== 'undefined') {
			checkState = this.state.data.external;
		}
		return (
			<div className="card">
				<div className="card-body">
					<div className="form-group">
						<label className="col-lg-2 kb-label">{I('Name')}</label>
						<div className="col-lg-9">
							<input type="text" name="name" value={libName} onChange={this.handleChange} className="form-control form-control-sm"/>
						</div>
						<div className="col-lg-1">
						<span className='kb-helper'><Helper>{I("The name of the knowledge base.")}</Helper></span>
						</div>
						<div className="row">
							<div style={{ color: 'red', marginLeft: '15px' }} className="help-block with-errors-name">{this.state.warningText}</div>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group external">
						<label className="col-lg-2 control-label">{I('External')}</label>
						<div className="col-lg-9">
							{/* <input
								name="external"
								type="checkbox"
								checked={checkState}
								onChange={this.handleChange}
							/>
							<span className="checkboxLabel"></span> */}
							<SwitchCheckbox
								active={checkState}
								buttonClassName={""}
								id={"knowledgeBaseExternal"}
								name={"external"}
								type="chekbox"
								onClick={this.handleToggle}/>
						</div>
						<div className="col-lg-1">
							<span className='kb-helper' style={{display: "inline-block", marginTop: "1.5px"}}><Helper>{I("Check this box if the knowledge base will be available on a web site. External knowledge bases are not shown internally to agents.")}</Helper></span>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group editPageActionButton">
						<div className="col-lg-12 textAlignRight">
							<CancelButton style={{fontSize:"12px",marginRight:"10px"}} onClick={this.props.onCancel} />
							<SaveButton
								key="btn-save-admin"
								onClick={this.handleSave}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default KnowledgeBaseEdit;
