import React, {
    Fragment
    , PureComponent
    , memo
    , useMemo
    , useEffect
    , useState,
    useRef
    ,useReducer
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import { AreaDropdown, ActiveBorderHighlightArraySingleSelect as SingleSelect } from '../../../reactcomponents/Dropdown';
import {
    ADMIN_VIEW_MAP,
    INPUT_EMPTY_WARNING
    , FORM_SUBMIT_EMPTY_WARNING
    , TXT_SELECT_COMPANY
    , ICON_CUSTOM_CHANNEL
    , OBJ_ID_NAME
    , CHANNEL_ICONS_MAP as iconMap
    , BTN_TXT_SAVE
    , BTN_TXT_SAVING
} from '../../../common/v5/constants';
import {
    TextInputRow,
    SelectInputRow,
    ReadOnlyTextField,
    CustomInputRow,
    FormInputWithLabelRow,
    TableIconicButton as Button
} from '../../../reactcomponents/Form';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';
import {
    TabContent
    , TabPane
    , Nav
} from 'reactstrap';
import {
	composeWithDisplayName,
	createWithMountCondition,
	omitProps,
	withUnmountWhenHidden
} from '../../../reactcomponents/hocs';
import { OneNavItem } from '../../../reactcomponents/NavTabs';
import { NoFlipMultiSelect } from '../../../reactcomponents/Dropdown';
import FolderSection from './groupFolder';
import AgentSection from './groupAgent';

const GroupTabs = (props) => {

    const [agents, setAgents] = useState("");
    const [teamLeaders, setTeamLeaders] = useState("");

    const TAB_GROUP = 1
        , TAB_AGENT = 2
        , TAB_FOLDER = 3

    const [activeTab, setActiveTab] = useState(TAB_GROUP);

    const handleUpdatedAgents = (agents) => {
        setAgents(agents)
    }

    const handleUpdatedTeamLeaders = (teamLeaders) => {
        setTeamLeaders(teamLeaders)
    }

    return (
        <section className={"group-tabs"}>
            <Nav tabs>
                <OneNavItem
                    active={activeTab === TAB_GROUP}
                    onClick={(v) => { setActiveTab(v) }}
                    tabId={TAB_GROUP}
                    text={I("Group")}
                    disabled={props.isNew}
                />
                <OneNavItem
                    active={activeTab === TAB_FOLDER}
                    onClick={(v) => { setActiveTab(v) }}
                    tabId={TAB_FOLDER}
                    text={I("Folder")}
                    hidden={props.isNew}
                />
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={TAB_GROUP}>
                    <GroupSection updatedAgents={agents} updatedTeamLeaders={teamLeaders}
                        onUpdateAgents={handleUpdatedAgents} onUpdateTeamLeaders={handleUpdatedTeamLeaders}
                        {...props} />
                </TabPane>
                <TabPane tabId={TAB_AGENT}>
                    <AgentSection onUpdateAgents={handleUpdatedAgents}
                        onUpdateTeamLeaders={handleUpdatedTeamLeaders}
                        {...props} />
                </TabPane>
                <TabPane tabId={TAB_FOLDER}>
                    <FolderSection {...props} />
                </TabPane>
            </TabContent>
        </section>
    )
}

const GroupSection = (props) => {
    let hidden = props.hidden
    let input = props.input
    let onChangeAdminInput = props.onChangeAdminInput
    let onSave = props.onSave
    let view = props.view
    let areaList = props.areaList
    let activeId = props.activeId

    const [showArea, setShowArea] = useState(false);

    const handleChangeData = (e) => {
        const { value, name } = e.target
        onChangeAdminInput(name, value);
    }
    const handleSelectArea = (value) => {
        // const { value, name } = e.target
        onChangeAdminInput("selectedArea", value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let param
        let members = props.updatedAgents
        let teamLeaders = props.updatedTeamLeaders

        param = {
            id: activeId,
            description: input.description,
            name: input.name,
            selectedAreas: input.selectedArea.toString(),
            fromGroup: true,
            memberList: members,
            teamLeaderList: teamLeaders
        };
        onSave(view, param);
    }

    let buttonSaveTxt = BTN_TXT_SAVE, disableSave = false;
    if(props.adminStatus && props.adminStatus.status === 1) {
        buttonSaveTxt = BTN_TXT_SAVING;
        disableSave = true;
    }

    return (
        <form id="adminGroupsEdit" onSubmit={handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
            <div className='input-section'>
                <TextInputRow
                    id={"name"}
                    name={"name"}
                    label={I("Name")}
                    className="admin-text-input"
                    value={input.name || ""}
                    onChange={handleChangeData}
                    mandatory={true}
                    inlineLabel={false}
                />
                <TextInputRow
                    id={"description"}
                    name={"description"}
                    label={I("Description")}
                    className="admin-text-input"
                    value={input.description || ""}
                    onChange={handleChangeData}
                    mandatory={false}
                    inlineLabel={false}
                />
                <FormInputWithLabelRow
                    label={I('Area')}
                    mandatory={true}
                    inlineLabel={false}
                >
                    <NoFlipMultiSelect
                        id={"area"}
                        title={I("Select Area")}
                        className="popup-multi-select admin-text-input-qrm"
                        overrideTitle={true}
                        groupSelect={true}
                        nested={{ key: 'Areas' }}
                        data={areaList}
                        idFields={OBJ_ID_NAME}
                        selected={input.selectedArea}
                        onSelect={handleSelectArea}
                        onToggle={() => setShowArea(!showArea)}
                        show={showArea}
                        selectAll={true}
                        selectNone={true}
                    />
                </FormInputWithLabelRow>
                <div className='action-wrapper' hidden = {false}>
                    <FormInputWithLabelRow
                        inlineLabel={false}
                    >
                    <AgentSection onUpdateAgents={props.onUpdateAgents} onUpdateTeamLeaders={props.onUpdateTeamLeaders} { ...props} />
                    </FormInputWithLabelRow>
                </div>
            </div>
            {/* {baseButtons} */}
            <div className='action-wrapper' style={{ display: "flex", float: "right" }} >
                {/* <Button type="button" onClick={onCancel} className="btn-grey" title="Cancel" data-qa-id="btn-cancel-account" label={I("Cancel")}/> */}
                <Button
                    type="submit"
                    disabled={(!input.name || !input.selectedArea.length || disableSave)}
                    className="btn-blue save"
                    title="Save"
                    data-qa-id="btn-add-account"
                    label={buttonSaveTxt}
                />
            </div>
            {/* <GroupTabs /> */}
        </form>
    )
}
export default GroupTabs;
