import React from 'react';
import { Tooltip } from 'reactstrap';
import update from 'react-addons-update';
import {
	KBT_CATEGORY
	, KBT_LIBRARY
	, KBT_QUESTION
} from '../../../common/v5/constants';
import { createMarkup } from '../../../common/v5/helpers';
class KnowledgeBaseTreeMouseOverButtons extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tabAppendState: {},
			answerAppended: false
		}
	}
	makeProp = (prop, value) => {
		var obj = {}
		obj[prop] = value
		return obj
	}
	handleAppend = () => {
		if (this.props.handleAppend) {
			this.props.handleAppend();
		}
		if (this.props.activeSessionId) {
			var activeSessionId = this.props.activeSessionId;
			var tabAppendState = update(this.state.tabAppendState, {
				$merge: this.makeProp(
					activeSessionId, true
				)
			});
			this.setState({ tabAppendState: tabAppendState })
		} else {
			this.setState({
				answerAppended: true
			});
		}
	}
	render() {
		var copyStyle = {
			cursor: 'pointer',
			display: (this.props.root === 'root' ? 'none' : 'auto')
		};
		var editStyle = {
			cursor: 'pointer',
			display: (this.props.showEdit ? 'auto' : 'none')
		};
		var removeStyle = {
			cursor: 'pointer',
			display: (this.props.showRemove ? 'auto' : 'none')
		};
		var appendStyle = {
			cursor: 'pointer',
			display: (this.props.showAppend ? 'auto' : 'none')
		};
		var insertStyle = {
			cursor: 'pointer',
			display: (this.props.showInsert ? 'auto' : 'none')
		};
		var style = {
			paddingLeft: '5px',
			float: 'right'
		};
		var appendClassName = 'glyphicon glyphicon-plus-sign';
		if (typeof this.props.activeSessionId !== "undefined" &&
			this.props.activeSessionId in this.state.tabAppendState) {
			appendClassName = 'glyphicon glyphicon-ok';
		} else {
			if (this.state.answerAppended) {
				appendClassName = 'glyphicon glyphicon-ok';
			}
		}
		return (
			<span style={style}>
				<span
					// className="far fa-edit"
					data-qa-id={'QA_edit_' + this.props.nodeType + '_' + this.props.id}
					aria-hidden="true"
					style={editStyle}
					title={I('Edit this node.')}
					onClick={this.props.handleEdit}>
					<i
						className='icon-edit'
					/>
				</span>
				<span style={{ paddingLeft: '5px' }}></span>
				{/* <span
					className="fas fa-copy"
					data-qa-id={'QA_copy_' + this.props.nodeType + '_' + this.props.id}
					aria-hidden="true"
					style={copyStyle}
					title={I('Copy this node.')}
					onClick={this.props.handleCopyNode}></span> */}
				<span style={{ paddingLeft: '5px' }}></span>
				<span
					// className="fas fa-times-circle"
					data-qa-id={'QA_remove_' + this.props.nodeType + '_' + this.props.id}
					aria-hidden="true"
					style={removeStyle}
					title={I('Remove node from this container.')}
					onClick={this.props.handleRemove}>
					<i
						className='icon-trash'
					/>
				</span>
				<span style={{ paddingLeft: '5px' }}></span>
				<span
					className={appendClassName}
					aria-hidden="true"
					style={appendStyle}
					title={I('Append this answer to your current answer.')}
					onClick={this.handleAppend}></span>
				<span style={{ paddingLeft: '5px' }}></span>
				<span
					className="glyphicon glyphicon-comment"
					aria-hidden="true"
					style={insertStyle}
					title={I('Insert this answer and replace your current answer.')}
					onClick={this.props.handleInsert}></span>
			</span>
		);
	}
};

KnowledgeBaseTreeMouseOverButtons.defaultProps = {
	showAppend: false,
	showInsert: false,
	showEdit: false,
	showRemove: false
};
class KnowledgeBaseTreeContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showButtons: false,
			showDropArea: false,
			subListExpanded: false
		}
	}
	componentWillMount() {
		if (this.props.expanded) {
			var state = this.state;
			state.subListExpanded = true;
			this.setState(state);
		}
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.expanded || nextProps.data.expanded) {
			this.setState({
				subListExpanded: true
			});
		}
	}
	showButtons = () => {
		this.setState({
			showButtons: true
		});
	}
	hideButtons = () => {
		this.setState({
			showButtons: false
		});
	}
	toggleSubList = () => {
		var state = this.state;
		state.subListExpanded = !state.subListExpanded;
		this.setState(state);
	}
	dragOver = (event) => {
		this.state.showDropArea = true;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	}
	dragEnter = (event) => {
		event.preventDefault();
		event.stopPropagation();
	}
	dragLeave = (event) => {
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
	}
	drop = (event) => {
		if (this.props.handleDrop) {
			if (this.state.subListExpanded) {
				this.props.handleDrop(event, null, this.props.data);
			} else if (this.props.container) {
				this.props.handleDrop(event, this.props.data, this.props.container);
			}
		}
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	}
	handlePaste = () => {
		this.props.handlePaste(this.props.data, this.props.container)
	}
	handlePasteInto = () => {
		this.props.handlePasteInto(this.props.data)
	}
	dragStart = (event) => {
		if (this.props.onDragStart) {
			this.props.onDragStart(event, this.props.data, this.props.container);
		}
		event.stopPropagation();
	}
	handleCopyNode = (event) => {
		this.props.handleCopyNode(event, this.props.data, this.props.container)
	}
	handleEdit = () => {
		this.props.handleEdit(this.props.type, this.props.data.id);
	}
	handleRemove = () => {
		this.props.handleRemove(this.props.data, this.props.container);
	}
	render() {
		var subListStyle = { display: 'none' };
		var toggleButtonClasses = 'icon-chevron-up';
		if (this.state.subListExpanded) {
			subListStyle.display = 'block';
			toggleButtonClasses = 'icon-chevron-down';
		}
		var liStyle = {
			paddingTop: '8px',
			paddingBottom: '8px'
		};
		var spanStyle = {
			paddingLeft: '10px',
			cursor: 'pointer'
		};
		var dropAreaStyle = {
			display: 'none',
			height: '22px',
			backgroundColor: 'transparent'
		};
		var showFirstItemDropArea = false;
		if (this.state.showDropArea) {
			if (this.state.subListExpanded) {
				showFirstItemDropArea = true;
			} else if (this.props.container) {
				dropAreaStyle.display = 'block';
			}
		}
		var draggable = false;
		if (this.props.draggable && this.props.container) {
			draggable = true;
		}
		let dataQaId = 'QA_book_', nodeType = 'library';
		if (this.props.iconClasses === 'icon-folder') {
			dataQaId = 'QA_file_';
			nodeType = 'category';
		}
		return (
			<li
				style={liStyle}
				onMouseOver={this.showButtons}
				onMouseOut={this.hideButtons}
				onDragOver={this.dragOver}
				onDragEnter={this.dragEnter}
				onDragLeave={this.dragLeave}
				onDrop={this.drop}
				draggable={draggable}
				onDragStart={this.dragStart}
				className="main-tree"
			>
				<div className="outer-container" style={{ marginLeft: '8px' }}>
					<div className="inner-container" style={{ background: 'transparent' }}>
						<span
							className="fas fa-arrow-alt-circle-down"
							data-qa-id={"QA_paste_below_" + this.props.data.id}
							style={{ cursor: 'pointer', paddingRight: '5px' }}
							aria-hidden="true"
							hidden={!this.props.copyState || this.props.root === 'root'}
							onClick={this.handlePaste}>
						</span>
						<span
							className="fas fa-level-down-alt"
							style={{ cursor: 'pointer', paddingRight: '5px' }}
							data-qa-id={"QA_paste_into_" + this.props.data.id}
							aria-hidden="true"
							hidden={!this.props.copyState}
							onClick={this.handlePasteInto}>
						</span>
						<span
							data-qa-id={'QA_toggle_button_' + this.props.data.id}
							style={{ cursor: 'pointer' }}
							aria-hidden="true"
							onClick={this.toggleSubList}>
								<i className={toggleButtonClasses}></i>
						</span>
						<span
							// className={this.props.iconClasses}
							data-qa-id={dataQaId + this.props.data.id}
							aria-hidden="true"
							style={spanStyle}
							onClick={this.toggleSubList}>
								<i className="icon-grip" style={{paddingRight: '10px'}}></i>
								<i className={this.props.iconClasses}></i>
							</span>
						<span
							style={spanStyle}
							onClick={this.toggleSubList}
							data-qa-id={'QA_list_tree_name_' + this.props.data.id}>
							{this.props.data.name} {this.props.type == KBT_LIBRARY ? "(ID:" + this.props.data.id + ")" : ""}
						</span>
						<KnowledgeBaseTreeMouseOverButtons
							id={this.props.data.id}
							nodeType={nodeType}
							show={this.state.showButtons}
							showEdit={this.props.showEdit}
							showRemove={this.props.showRemove}
							handleEdit={this.handleEdit}
							activeSessionId={this.props.activeSessionId}
							handleRemove={this.handleRemove}
							copyState={this.props.copyState}
							handlePaste={this.handlePaste}
							handleCopyNode={this.handleCopyNode}
							root={this.props.root} />
					</div>
					<div className="sub-tree-list" style={subListStyle}>
						{this.props.renderItems(this.props.data.list, this.props.data, showFirstItemDropArea)}
					</div>
				</div>
				<div style={dropAreaStyle}></div>
			</li>
		);
	}
};

KnowledgeBaseTreeContainer.defaultProps = {
	expanded: false,
	showEdit: false,
	showRemove: false,
	draggable: false
}

const modifiers = {
	offset: { offset: '100px' },
	preventOverflow: { enabled: false },
	hide: { enabled: false }
}

class KnowledegeBaseQuestionPreview extends React.Component {
	constructor(props) {
		super(props);
	};
	render() {
		// TODO: there is bug for targetElement because knowledge tree can have
		// duplicated id because question repeat in different library node. When
		// that happens tooltip only find the first one, if it can't find it it will
		// place tooltip at corner.
		const { data, freezeToolTip, show, targetElement, edit } = this.props;
		var freezing = freezeToolTip;
		if (edit.show) {freezing = false};
		return (
			<Tooltip
				className='knowledgebases-preview'
				hideArrow={true}
				isOpen={show || freezing}
				modifiers={modifiers}
				placement='bottom-start'
				target={targetElement}
			>
				<div onMouseOver={this.freezePreview} onMouseOut={this.unfreezePreview}>
					<div className={"knowledgebases-preview-title"}>{I('Question')}</div>
					<div className="knowledgebases-preview-content" dangerouslySetInnerHTML={createMarkup(data.question)} />
					<div className={"knowledgebases-preview-title"}>{I('Answer')}</div>
					<div className="knowledgebases-preview-content" dangerouslySetInnerHTML={createMarkup(data.answer)} />
				</div>
			</Tooltip>
		)
	};
};

//KnowledgeBaseQuestionPreviewPopup
const KBQuestionPreviewPopup = ({ close, data, }) => {
	return (
		<div className="popup open knowledgebase-preview">
			<div className="popup-inner">
				<div className="popup-close" onClick={close}><i className="icon-times"></i></div>
				<div className="popup-content-wrapper">
					<div className={"knowledgebases-preview-title"}>{I('Question')}</div>
					<div className="knowledgebases-preview-content" dangerouslySetInnerHTML={createMarkup(data.question)} />
					<div className={"knowledgebases-preview-title"}>{I('Answer')}</div>
					<div className="knowledgebases-preview-content" dangerouslySetInnerHTML={createMarkup(data.answer)} />
				</div>
			</div>
		</div>
	)
}

class KnowledgeBaseTreeQuestion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timeGap: 0,
			showButtons: false,
			showButtons: false,
			showDropArea: false,
			showPreview: false,
			showToolTip: false,
			freezePreview: false
		}
	}
	showButtons = () => {
		this.setState({
			showButtons: true
		});
	}
	hideButtons = () => {
		this.setState({
			showButtons: false
		});
	}
	showPreview = () => {
		this.setState({ showToolTip: true });
	}
	hidePreview = () => {
		this.setState({ showToolTip: false })
	}
	dragOver = (event) => {
		this.state.showDropArea = true;
		this.state.showPreview = false;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	}
	dragEnter = (event) => {
		event.preventDefault();
		event.stopPropagation();
	}
	dragLeave = (event) => {
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
	}
	drop = (event) => {
		if (this.props.handleDrop) {
			this.props.handleDrop(event, this.props.data, this.props.container);
		}
		this.state.showDropArea = false;
		this.setState(this.state);
		event.preventDefault();
		event.stopPropagation();
	}
	handlePaste = () => {
		this.props.handlePaste(this.props.data, this.props.container)
	}
	dragStart = (event) => {
		this.state.showPreview = false;
		this.setState(this.state);
		if (this.props.onDragStart) {
			this.props.onDragStart(event, this.props.data, this.props.container);
		}
		event.stopPropagation();
	}
	handleCopyNode = (event) => {
		this.props.handleCopyNode(event, this.props.data, this.props.container)
	}
	handleEdit = () => {
		const { type, data } = this.props;
		this.props.handleEdit(type, data.id);
	}
	handleRemove = () => {
		this.props.handleRemove(this.props.data, this.props.container);
	}
	handleInsert = () => {
		this.props.handleInsert(this.props.data);
	}
	handleAppend = () => {
		this.props.handleAppend(this.props.data);
	}
	handleClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}
	onFreezePreview = () => {
		this.setState({ freezePreview: !this.state.freezePreview })
	}
	renderShortcutSetup = (shortcuts) => {
		if (shortcuts.key != "") {
			var mod = [];
			if (shortcuts.alt) {
				mod.push("Alt");
			}
			if (shortcuts.ctrl) {
				mod.push("Ctrl");
			}
			if (shortcuts.meta) {
				mod.push("Meta");
			}
			if (shortcuts.shift) {
				mod.push("Shift");
			}
			var mods = mod.join(" + ");
			return "(" + mods + " + " + shortcuts.key + ")";
		} else {
			return "";
		}
	}
	render() {
		const p = this.props
			, targetElement = 'tooltip-kb-' + p.data.id;
		;
		let toolTipComponent = null;
		var style = {
			cursor: 'default',
			paddingTop: '4px',
			paddinBottom: '4px',
			// paddingRight: '8px',
			backgroundColor: p.backgroundColor
		};
		var titleStyle = {
			paddingLeft: '10px'
		};
		var dropAreaStyle = {
			display: 'none',
			height: '22px',
			backgroundColor: 'transparent'
		};
		var timeStyle = {
			paddingLeft: '5px'
		};
		var shortcutInfo = {
			key: p.data.keyboardShortcut,
			alt: (p.data.keyboardShortcutUseAlt ? true : false),
			ctrl: (p.data.keyboardShortcutUseCtrl ? true : false),
			meta: (p.data.keyboardShortcutUseMeta ? true : false),
			shift: (p.data.keyboardShortcutUseShift ? true : false)
		}
		if (this.state.showDropArea) {
			dropAreaStyle.display = 'block';
		}
		if (this.state.showPreview) {
			style.backgroundColor = 'transparent';
		}
		if (!p.showTimeControlled || !p.data.timeControlled) {
			timeStyle.display = 'none';
		} else if (p.showTimeControlled && !p.data.active) {
			titleStyle.color = 'red';
		}
		if (this.state.showToolTip || this.state.freezePreview) {
			toolTipComponent = (
				!p.mobile ?
					<KnowledegeBaseQuestionPreview
						targetElement={targetElement}
						show={this.state.showToolTip}
						data={p.data}
						id={p.data.id}
						edit={p.edit}
						freezeToolTip={this.state.freezePreview} /> :
					<KBQuestionPreviewPopup
						close={this.hidePreview}
						mobile={true}
						open={true}
						data={p.data}
					/>
			)
		};
		if (this.state.freezePreview) {
			var titleStyle = {
				paddingLeft: '10px',
				fontWeight: '600'
			};
		}
		return (
			<li style={style}
				onMouseOver={this.showButtons}
				onMouseOut={this.hideButtons}
				onDragOver={this.dragOver}
				onDragEnter={this.dragEnter}
				onDragLeave={this.dragLeave}
				onDrop={this.drop}
				draggable={p.draggable}
				onDragStart={this.dragStart}
			>
				<div style={{ marginLeft: '8px', padding: '1px 0'}}>
					{/* className="fas fa-tag */}
					<span aria-hidden="true" data-qa-id={'QA_tag_' + this.props.data.id}>
						<i className="icon-grip" style={{paddingRight: '10px'}}></i>
						<i className="icon-report-executive"></i>
					</span>
					<span
						id={targetElement}
						onClick={this.onFreezePreview}
						onMouseEnter={this.showPreview}
						onMouseLeave={this.hidePreview}
						data-qa-id={'QA_tree_list_name_' + this.props.data.id}
						style={titleStyle}>
						{p.data.name}
						{toolTipComponent}
					</span>
					<span className="libraryQuestionShortcut"> {p.showChatShortcut ? this.renderShortcutSetup(shortcutInfo) : ""} </span>
					<i className="fa fa-clock-o" style={timeStyle}></i>
					<KnowledgeBaseTreeMouseOverButtons
						id={this.props.data.id}
						nodeType="question"
						show={this.state.showButtons}
						showEdit={p.showEdit}
						showRemove={p.showRemove}
						showInsert={p.showInsert}
						showAppend={p.showAppend}
						activeSessionId={p.activeSessionId}
						handleEdit={this.handleEdit}
						handleRemove={this.handleRemove}
						handleInsert={this.handleInsert}
						copyState={this.state.copyState}
						handleCopyNode={this.handleCopyNode}
						handleAppend={this.handleAppend} />
				</div>
				<div style={dropAreaStyle}></div>
			</li>
		);
	}
};

KnowledgeBaseTreeQuestion.defaultProps = {
	showAppend: false,
	showInsert: false,
	showEdit: false,
	showRemove: false,
	enablePreview: true,
	previewPosition: 'left',
	previewMaxHeight: 0,
	previewShown: false,
	showTimeControlled: false,
	showChatShortcut: false
}
class KnowledgeBaseTree extends React.Component {
	constructor(props) {
		super(props);
		this.renderItems = this.renderItems.bind(this);
		this.state = {
			previewShown: false,
		}
	}
	componentWillMount() {
		this.load(this.props);
	}
	componentDidUpdate(prevProps) {
		if (this.props.library != prevProps.library ||
			this.props.category != prevProps.category ||
			this.props.insertedNode != prevProps.insertedNode ||
			this.props.deletedId !== prevProps.deletedId ||
			this.props.savedId !== prevProps.savedId ||
			this.props.removedNode !== prevProps.removedNode) {
			this.load(this.props);
		}
		if (this.props.searchState && this.props.searchState !== prevProps.searchState) {
			this.load(this.props);
		} else if (this.props.searchSource && this.props.searchSource !== prevProps.searchSource) {
			this.load(this.props);
		}
	}
	load = (props) => {
		if (parseInt(props.library, 10) > 0) {
			this.loadLibrary(props.library, props.search);
		} else if (parseInt(props.category, 10) > 0) {
			this.loadCategory(props.category, props.search);
		}
	}
	loadLibrary = (id, search) => {
		this.loadFromServer(id, search, "library");
	}
	loadCategory = (id, search) => {
		this.loadFromServer(id, search, "category");
	}
	loadFromServer = (id, search, type) => {
		var parameters = {};
		if (search) {
			parameters.search = search;
			parameters.source = 0; /* either */
			switch (this.props.searchSource) {
				case 'either': parameters.source = 0; break; // either
				case 'both': parameters.source = 1; break; // both
				case 'question': parameters.source = 2; break; // question
				case 'answer': parameters.source = 3; break; // answer
			}
		}
		if (type === KBT_LIBRARY) {
			this.props.onFetchKnowledgeBase(id, parameters)
		} else {
			this.props.onFetchKnowledeBaseCategory(id, parameters)
		}
	}
	togglePreviewShown = (isShown) => {
		this.setState({ previewShown: isShown });
	}
	renderItems = (items, container, showDropArea) => {
		var pStyle = {
			fontSize: '12px',
			paddingLeft: '4px'
		};
		if (!items) {
			pStyle.display= 'none';
			return (<div></div>);
		}
		const {
			activeSessionId
			, copyState
			, draggable
			, enablePreview
			, handleAppend
			, handleCategoryRemove
			, handleCopyNode
			, handleDrop
			, handleInsert
			, handleLibraryRemove
			, handlePaste
			, handlePasteInto
			, handleQuestionRemove
			, mobile
			, onDragStart
			, onHandleEdit
			, onHidePreview
			, onShowPreview
			, previewMaxHeight
			, previewPosition
			, showAppend
			, showChatShortcut
			, showEdit
			, showInsert
			, showTimeControlled
			, edit
		} = this.props;	
		
		var renderedItems = items.map((item, i) => {
			var bgColor = 'transparent';
			var showRemove = this.props.showRemove;
			if (!container) {
				showRemove = false;
			}
			pStyle.display= 'none';
			switch (item.type) {
				case KBT_LIBRARY:
					var key = (item.nodeId ? item.nodeId : 'root');
					var expanded = (key == 'root' ? true : false);
					pStyle = {
						display: 'none'
					};
					return (
						<KnowledgeBaseTreeContainer
							activeSessionId={activeSessionId}
							container={container}
							copyState={copyState}
							data={item} draggable={draggable}
							expanded={expanded}
							handleCopyNode={handleCopyNode}
							handleDrop={handleDrop}
							handleEdit={onHandleEdit}
							handlePaste={handlePaste}
							handleRemove={handleLibraryRemove}
							iconClasses={"icon-knowledgebase"}
							key={key} onDragStart={onDragStart}
							renderItems={this.renderItems}
							root={key} showEdit={showEdit}
							showRemove={showRemove}
							handlePasteInto={handlePasteInto}
							type={KBT_LIBRARY}
						/>
					);
				case KBT_CATEGORY:
					var key = (item.nodeId ? item.nodeId : 'root');
					pStyle = {
						display: 'none'
					};
					return (
						<KnowledgeBaseTreeContainer
							activeSessionId={activeSessionId}
							container={container}
							copyState={copyState}
							data={item} draggable={draggable}
							handleCopyNode={handleCopyNode}
							handleDrop={handleDrop}
							handleEdit={onHandleEdit}
							handlePaste={handlePaste}
							handleRemove={handleCategoryRemove}
							iconClasses={"icon-folder"}
							key={key} onDragStart={onDragStart}
							renderItems={this.renderItems}
							root={key} showEdit={showEdit}
							showRemove={showRemove}
							handlePasteInto={handlePasteInto}
							type={KBT_CATEGORY}
						/>
					);
				case 'question':
					pStyle = {
						display: 'none'
					};
					return (
						<KnowledgeBaseTreeQuestion
							activeSessionId={activeSessionId}
							backgroundColor={bgColor}
							container={container}
							copyState={copyState}
							data={item} draggable={draggable}
							enablePreview={enablePreview}
							handleAppend={handleAppend}
							handleCopyNode={handleCopyNode}
							handleDrop={handleDrop}
							handleEdit={onHandleEdit}
							handleInsert={handleInsert}
							handlePaste={handlePaste}
							handleRemove={handleQuestionRemove}
							key={item.nodeId}
							onDragStart={onDragStart}
							onHidePreview={onHidePreview}
							onShowPreview={onShowPreview}
							previewMaxHeight={previewMaxHeight}
							previewPosition={previewPosition}
							previewShown={this.state.previewShown}
							showAppend={showAppend}
							showChatShortcut={showChatShortcut}
							showEdit={showEdit} showInsert={showInsert}
							showRemove={this.props.showRemove}
							showTimeControlled={showTimeControlled}
							togglePreviewShown={this.togglePreviewShown}
							type={KBT_QUESTION}
							mobile={mobile}
							edit={edit}
						/>
					);
			}
		});
		var style = {
			listStyleType: 'none',
			fontSize: '14px',
			paddingLeft: '16px'
		};
		var dropAreaStyle = {
			display: 'none',
			height: '22px',
			backgroundColor: 'transparent',
			paddingTop: '4px',
			paddinBottom: '4px',
			paddingLeft: '8px',
			paddingRight: '8px'
		};
		if (showDropArea) {
			dropAreaStyle.display = 'block';
		}
		return (
			<ul className="knowledgebase-tree-container" style={style}>
				<div className="list-container">
					<li style={dropAreaStyle}></li>
					<p style={pStyle}>Select or Drag knowledge base from the table</p>
					{renderedItems}
				</div>
			</ul>
		);
	}
	render() {
		return this.renderItems(this.props.list, null);
	}
};

KnowledgeBaseTree.defaultProps = {
	library: 0,
	category: 0,
	search: '',
	searchSource: 'either',
	showAppend: false,
	showInsert: false,
	showEdit: false,
	showRemove: false,
	draggable: false,
	enablePreview: true,
	previewPosition: 'bottom',
	previewMaxHeight: 0,
	showTimeControlled: false,
	showChatShortcut: false
}

export default KnowledgeBaseTree;
