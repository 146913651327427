import {
	ReplyForm,
	InternalMessage
} from './internalMsg';
import { connect } from 'react-redux';
import {
	selectShowReply,
	selectShowReplyAction,
	toggleRecipients,
	// toggleErrandHeaderMobile,
	// toggleErrandOptionMobile,
	// recipientsChange
} from '../../redux/actions/errand';
// import {
// 	agentTimezoneOffsetSelector
// } from '../../redux/selectors/errand';
import {
	getSelectedIds,
	getTotalSelectedMessages,
	isMsgHistoryReady,
	isAcquireReady
} from '../../redux/selectors/imrds';
import {
	mobileStateSelector
} from '../../redux/selectors/mobile';

import {
	// JUST_LOADED_STR,
	UNSELECT_STR,
	OPEN_STR,
	BTN_CLEAR,
	BTN_SEND,
	BTN_SAVE,
	E_A_SENDING,
	E_A_SAVING,
	RPLY_IM,
	ME_ST_IDLE,
	ST_JUST_LOGIN,
	ST_UNSELECT,
	ST_ACQUIRED
} from '../../common/v5/constants';

import {
	// IMDraftCounter,
	inputTextChanges,
	IMState
} from '../../redux/actions/internalMessages';

import {
	submitIM,
	doDeleteMessage,
	doRestoreMessage,
	fetchInternalMessages,
	saveIM
} from '../../redux/actions/async/internalMessages';

import {
	ScrollToBottom
} from '../v5/errand';

// import {
// 	getIMCounter,
// } from '../../redux/actions/async/ajax';

const mapReply = (state, props) => {
	const e = state.app.errand, // TODO: switch it back to use e.currentErrand.id
		wf = state.app.workflow,
		im = state.app.internalMessage,
		ui = e.ui.reply, inpts = im.inputs, s = {
			wfSettings: wf.fetchWfSettings.data,
			showRecipients: ui.showRecipients,
			showChannelSelection: ui.showChannel,
			showActionSelection: ui.showAction,
			sendBusy: inpts.answer_state === E_A_SENDING,
			saveBusy: inpts.answer_state === E_A_SAVING,
			subject: inpts.update_subject,
			to: inpts.update_to,
			answer: inpts.update_answer
		};
	return s;
};

const mapIMReplyDispatch = (dispatch, props) => {
	return {
		onToggleAction: toggleState => {
			dispatch(selectShowReplyAction(toggleState));
		},
		onSelectAction: action => {
			dispatch(selectShowReplyAction(action));
		},
		onToggleRecipients: toggleState => {
			dispatch(toggleRecipients(toggleState));
		},
		onAnswerSubjectChange: value => {
		},
		onAnswerChange: (value, plain) => {
			dispatch(inputTextChanges('update_answer', value, plain, RPLY_IM));
			dispatch(IMState(ME_ST_IDLE));
		},
		// onRecipientsChange: (recipients, which) => {
		// 	dispatch(recipientsChange(recipients, which));
		// },
		onButtonClick: type => {
			if(type === BTN_CLEAR) {
				dispatch(inputTextChanges('update_answer', '', RPLY_IM));
			} else if(type === BTN_SEND) {
				dispatch(submitIM("to", RPLY_IM));
			} else if(type === BTN_SAVE) {
				dispatch(saveIM(type, RPLY_IM));
				dispatch(fetchInternalMessages(4))
			} else {
				console.log('dbg: unknown button type', type);
			}
		}
	};
};

export const ReplyFormCtnr = connect(mapReply,
	mapIMReplyDispatch)(ReplyForm);

const getCurrentState = (currentErrand,resetState) => {
	let res = {state: ST_JUST_LOGIN};
	switch(currentErrand.type) {
		case UNSELECT_STR:
			res.state = ST_UNSELECT;
			break;
		case OPEN_STR:
			res.state = ST_ACQUIRED;
			break;
	}
	return res;
};

const mapIMStateToProps = (state, props) => {
	const wf = state.app.workflow,
		m = state.app.internalMessage, cm = m.currentMessage,
		eui = state.app.errand.ui, s = {
			eui,
			wfSettings: wf.fetchWfSettings.data,
			messageId: cm.id,
			currentState: getCurrentState(cm, wf.filter ? wf.filter.resetErrandView : false),
			showIMReply: m.ui.showIMReply,
			mobile: mobileStateSelector(state, props),
			message: cm.data,
			data: props.messagelist.norm,
			filter: m.ui.filter,
			selectedMessages: getSelectedIds(m.messageList.data ? m.messageList.data : []),
			totalSelected: getTotalSelectedMessages(m.messageList.data ? m.messageList.data : [],),
			agentList: wf.agents.data.agents, //m.agents.data.agents,
			groupList: wf.agentGroups.data.groups,
			historyReady: isMsgHistoryReady(state),
			imReady: isAcquireReady(state),
			fetchHistory: m.fetchHistory,
			isNewThread: m.ui.isNewThread,
			collapseSideBar: wf.ui.collapseSideBar,
			showSideBar: wf.ui.showSideBar
		};
	return s;
};

const mapIMPropsCallbacks = dispatch => {
	return {
		onToggleReply: () => {
			dispatch(selectShowReply());
		},
		onSelectReply: (which, show) => {
			dispatch(selectShowReply(which, show));
		},
		// onToggleErrandHeaderMobile: toggleState => {
		// 	dispatch(toggleErrandHeaderMobile(toggleState));
		// },
		// onToggleErrandOptionMobile: toggleState => {
		// 	dispatch(toggleErrandOptionMobile(toggleState));
		// },
		onDeleteMessages: (ids, folderId) => {
			dispatch(doDeleteMessage(ids, true));
		},
		onRestoreMessages: (ids, folderId) => {
			dispatch(doRestoreMessage(ids, true));
		},
	};
};

const InternalMessageCtnr = connect(mapIMStateToProps, mapIMPropsCallbacks)(InternalMessage);

export default InternalMessageCtnr;

const mapStateToScrollToBottom = (state) => {
	const im = state.app.internalMessage;
	const wfs = state.app.workflow.fetchWfSettings;
	let answerBoxHeight = state.server.features["workflow.answerbox-height"];
	return {
		preferredAnswerBoxHeight: answerBoxHeight,
		showReply: im.ui.showIMReply,//reply.show,
		verticalView: wfs.data.verticalView
	};
}

export const ScrollToBottomCtnr = connect(mapStateToScrollToBottom)(ScrollToBottom)
