import React, {
    Fragment
    , PureComponent
    , memo
    , useMemo
    , useEffect
    , useState,
    useRef
} from 'react';
import { I, webRoot } from '../../../common/v5/config';
import classNames from 'classnames';
import {
    ListHeaderWrapper,
    ListContentWrapper,
} from '../../../reactcomponents/Admin';
import { AreaDropdown, ActiveBorderHighlightArraySingleSelect as SingleSelect } from '../../../reactcomponents/Dropdown';
import {
    ADMIN_VIEW_MAP,
    ADMIN_TITLE,
    INPUT_EMPTY_WARNING
    , FORM_SUBMIT_EMPTY_WARNING
    , TXT_SELECT_COMPANY
    , ICON_CUSTOM_CHANNEL
    , OBJ_ID_NAME
    , CHANNEL_ICONS_MAP as iconMap,
    BTN_TXT_SAVE
} from '../../../common/v5/constants';
import { renderFileActionBtns as renderActionButtons } from '../admin';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
    TextInputRow,
    SelectInputRow,
    ReadOnlyTextField,
    CustomInputRow,
    FormInputWithLabelRow,
    TableIconicButton as Button
} from '../../../reactcomponents/Form';
import { TXT_NO_SELECTION } from '../../../common/v5/receiptGreetingConstants';
import {
    TabContent
    , TabPane
    , Nav
} from 'reactstrap';
import { OneNavItem } from '../../../reactcomponents/NavTabs';
// import ContactCardsNotes from './contactCardsNotes'
import AvatarEditor from 'react-avatar-editor';
import { NoFlipMultiSelect } from '../../../reactcomponents/Dropdown';
import { TableHeader } from './groups';
import { customPageTotal } from '../../../reactcomponents/Table';
import { ModalBox } from '../../../reactcomponents/Modal';

const helperForFolder = <div>
			<div>{I("Group folder will show up in workflow and can be used to move around errands.")}</div>
			<div>{I("Errands that are moved into the folder will be shown for agents in the group.")}</div>
		</div>

const FolderList = ({ view, show, columns, list, options, onCreate, folderListReady }) => {
    return (
        <div id="FolderList">
            <ListHeaderWrapper>
                <TableHeader
                    title={I("Group Folder")}
                    helperText={helperForFolder}
                    type='button'
                    iconClass='icon-add'
                    hide={false}
                >
                    <Button
                        type='button'
                        className={classNames("btn bg-transparent btn-round", { "active": false })}
                        iconClass='icon-add'
                        title={I('Create New')}
                        onClick={onCreate}
                        hide={false}
                        active={show}
                    />
                </TableHeader>
            </ListHeaderWrapper>
            {folderListReady ?
                <ListContentWrapper className={"v5-table-style"}>
                    <BootstrapTable
                        keyField="id"
                        data={list}
                        noDataIndication={I("No data to display")}
                        columns={columns}
                        bordered={false}
                        condensed={false}
                        // defaultSorted={defaultSorted}
                        pagination={paginationFactory(options)}
                        hover
                    />
                </ListContentWrapper>
                : <p>Loading...</p>
            }
        </div>
    )
}

const FolderEditHeader = ({title}) => <span className='heading'><i className='icon-folder'></i><h6>{title}</h6></span>

const FolderEdit = ({ handleSubmit, input, handleChangeData, hidden, onDelete, onCancel, adminStatus }) => {

    const handleRemoveFolder = () => {
        onDelete(input.folder_id);
        onCancel();
    };

    let buttonSaveTxt = BTN_TXT_SAVE, disableSave = false;
    if (adminStatus && adminStatus.status === 1) {
        buttonSaveTxt = I("Saving...");
        disableSave = true;
    }

    return (
        <form id="adminFolderEditForm" onSubmit={handleSubmit} className="admin-one-form edit-admin-form" hidden={hidden}>
            <div className='input-section'>
                <TextInputRow
                    id={"folder_name"}
                    name={"folder_name"}
                    label={I("Name")}
                    className="admin-text-input"
                    value={input.folder_name || ""}
                    onChange={handleChangeData}
                    mandatory={true}
                    inlineLabel={false}
                />
                <TextInputRow
                    id={"folder_description"}
                    name={"folder_description"}
                    label={I("Description")}
                    className="admin-text-input"
                    value={input.folder_description || ""}
                    onChange={handleChangeData}
                    mandatory={false}
                    inlineLabel={false}
                />
            </div>
            <div className='action-wrapper'>
                <div className='left-end'>
                    {input.folder_id ?
                        <Button iconClass={"icon-trash"}
                            type="button"
                            onClick={handleRemoveFolder}
                            className="btn-transparent delete"
                            title={I("Delete")}
                            data-qa-id="btn-delete-folder"
                            label={I("Remove")}
                            iconPosition={"left"}
                        />
                        : null
                    }
                </div>
                <div className='right-end'>
                    <Button type="button" onClick={onCancel} className="btn-transparent border-0" title="Cancel" data-qa-id="btn-cancel-folder" label={I("Cancel")} />
                    <Button type="submit" disabled={!input.folder_name || disableSave} className="btn-blue save" title={buttonSaveTxt} data-qa-id="btn-add-folder" label={buttonSaveTxt} />
                </div>
            </div>
        </form>
    )
}

const FolderSection = (props) => {
    const [popup, setPopup] = useState(false);
    const [createFolder, setCreateFolder] = useState(true);

    const handleChangeData = (e) => {
        const { value, name } = e.target
        props.onChangeAdminInput(name, value);
    }

    const handleEditFolder = (id) => {
        props.onLoadToEditFolder(id)
        setCreateFolder(id === 0 ? true : false);
        setPopup(true);
    }

    const handleDeleteFolder = (id) => {
        let params = { id: id, gid: props.activeId }
        let confirmDelMsg = I('Are you sure you want to delete?');

        if (confirm(confirmDelMsg)) {
            props.onDeleteFolderFromList(params)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let id = props.input.folder_id || 0; // 0 wil create New if not specified
        let param = {
            gid: props.activeId,
            id: id,
            description: props.input.folder_description,
            name: props.input.folder_name
        };
        props.onSaveGroupFolder(props.view, param);
        setPopup(false);
    }

    const actionDatas = {
        onDelete: handleDeleteFolder,
        onEdit: handleEditFolder
    }

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: I('Name'),
            sort: true,
        },
        {
            isDummyField: true,
            dataField: 'action',
            text: I('Action'),
            headerAlign: (column, colIndex) => 'right',
            classes: 'right',
            formatter: (cell, row) => {
                return renderActionButtons(cell, row, actionDatas);
            },
        }
    ]

    const options = {
        paginationSize: 3,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '<<',
        prePageText: '<',
        nextPageText: '>',
        lastPageText: '>>',
        showTotal: true,
        paginationTotalRenderer: customPageTotal,
        disablePageTitle: true,
        sizePerPageList: [10]
    };

    let list = props.folderList;
    let folderHeaderTitle = createFolder ? I("Create Group Folder") : I("Edit Group Folder");

    return (
        <Fragment>
            {list &&
                <FolderList
                    columns={columns}
                    show={true}
                    list={list}
                    options={options}
                    setPopup={setPopup}
                    onCreate={() => handleEditFolder(0)}
                    {...props}
                />
            }
            {popup && <ModalBox
                show={popup}
                onClose={() => { setPopup(false) }}
                className={"folder-edit"}
                headerContent={<FolderEditHeader title={folderHeaderTitle}/>}
            >
                {props.folderReady ?
                    <FolderEdit
                        input={props.input}
                        hidden={false}
                        handleChangeData={handleChangeData}
                        handleSubmit={handleSubmit}
                        onDelete={handleDeleteFolder}
                        onCancel={() => { setPopup(false) }}
                        adminStatus={props.adminStatus}
                    />
                    : <p>Loading...</p>
                }
            </ModalBox>}
        </Fragment>
    )
}

export default FolderSection;