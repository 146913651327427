import React, { Component } from 'react';
import {I} from '../../common/v5/config.js';
import classNames from 'classnames';
import SidebarSettingsList from '../../reactcomponents/SidebarSettingsList';
import AppHeader from '../../components/v5/AppHeader';
import MessageView from '../../components/v5/MessageView';
import {
	PlusButton
	, SidebarButtonsList
	, SidebarIconItem
} from '../../components/v5/SidebarSettings';
import { IconizeSideBarMenuCtnr, WorkflowInnerContainerCtnr, SidebarSettingsCtnr } from './workflowCtnr';
import InternalMessageCtnr from './internalMsgCtnrs';
import { MM_MESSAGE } from '../../common/v5/constants';
import MenuCtnr from '../../common/v5/menuCtnr';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import { isMobile } from '../../redux/util';

const PlusIM = ({collapseSideBar, ...props}) => {
	if(collapseSideBar) {
		return <a title={I('Create message')} {...props}><i className="icon-mail-new"></i></a>
	}
	return (
		<PlusButton data-qa-id="im-create-msg" {...props}>{I('Create message')}</PlusButton>
	)
}

class InternalMessagesList extends React.Component {
	constructor(props) {
		super(props);
		this.toggleManualIM = this.toggleManualIM.bind(this);
		this.handleSideBarToggle = this.handleSideBarToggle.bind(this);
		this.handleSideBarCollapse = this.handleSideBarCollapse.bind(this);
	}
	componentDidMount() {
		this.props.onLoad();
		// this.props.onLoadSetSelected();
		window.addEventListener('resize', this.handleWorkflowResize);
		if (this.props.wfui.showMobileView){
			this.props.onToggleSideBar(false);
		}
	}
	componentWillUnmount() {
		this.props.onUnload();
		window.removeEventListener('resize', this.handleWorkflowResize);
	}
	toggleManualIM() {
		this.props.onToggleShowManualIM(!this.props.showIMPopAddMessage, false);
	}
	handleSideBarToggle() {
		this.props.onToggleSideBar(!this.props.wfui.showSideBar);
	}
	handleSideBarCollapse() {
		this.props.onCollapseSideBar(!this.props.wfui.collapseSideBar);
	}
	handleSortList = (val, index, dir) => {
		let params = {};
		this.props.onToggleMessageListSort(val, index);
		if(typeof dir !== 'undefined' && val == 'date') {
			params.sort_attribute = 'date';
			params.sort_direction = dir;
		}else{
			//Note: Do we have sort by channel ? A:No chennel needed for IM
		}
		this.props.setParams(params);
		this.props.setLoadList();
	}

	handleSelectOneMessage = (id, select) => {
		this.props.onSelectMessageFromList(id, select);
	}

	handleSelectAllMessages = selectAll => {
		this.props.onSelectAllMessages(selectAll);
	}

	handleLoadMore = () => {
		this.props.setParams({
			size: this.props.listInputs.size+this.props.wfs.agentInboxSize
		});
		this.props.setLoadList();
	}

	handleSingleMessageClick = id => {
		// if(this.props.wfui.showMobileView) {
		// 	this.props.onSetActivateErrandMobileView(true);
		// }
		this.props.onOpenCurrentMessage(id);
	}

	handleDoubleMessageClick = id => {
		this.props.onToggleShowManualIM(true, true, id);
	}

	handleSortTableList(sortBy, order){
		this.props.setParams({
			sort_attribute: sortBy,
			sort_direction: order
		});
		this.props.setLoadList();
	}

	handleWorkflowResize = () => {
		if(isMobile) {
			this.props.onSetMobileView(true);
			this.props.onToggleSideBar(false);
		}else{
			this.props.onSetMobileView(false);
			this.props.onToggleSideBar(true);
		}
	}
	handleCloseMessageView = (id, select) => {
		this.props.onCloseMessageView();
	}
	render =()=>{
		const {
			wfs,
			isFetching,
			loadingMessageList,
			wfui,
			listInputs,
			messagelist,
			filter,
			onToggleShowSort,
			// onSwitchToListView,
			onDeleteMessages,
			onRestoreMessages,
			selectedMessages,
			setLoadList,
			ui,
			activateChat,
			onChangeAdminView,
			totalSelected,
			currentMessageID
		} = this.props

		if(isFetching) {
			return <h1>{I('loading ...')}</h1>;
		}
		let layoutClass = "layout slim";
		let hide = true;
		let backNavHide = true;
		if(wfui.showMobileView){
			hide = true;
			if(wfui.showSideBar) {
				hide = false;
				layoutClass = "layout slim closed-sidebar";
			}
			if(wfui.showErrandMobileView) {
				 backNavHide = false;
				if(wfui.showSideBar){
					backNavHide = true;
				}
			}
		}else{
			hide = false;
			if(!wfui.showSideBar) {
				layoutClass = "layout slim closed-sidebar";
			}
		}
		let listTotalCount = 0;
		if( messagelist ) {
			listTotalCount = Object.keys(messagelist.norm).length;
		}
		if(activateChat) {
			layoutClass += " opened-chat";
		}
		let sidebarClass = "", flexMenusStyle = {};
		if(wfui.collapseSideBar){
			sidebarClass = "collapsed";
			flexMenusStyle = {padding: "0px"};
		}
		return(
			<div className="internal-message">
				<div id="page-wrapper">
					<section className={layoutClass} data-app-layout>
						<div className={classNames("app-sidebar", sidebarClass)}>
							<AppHeader backNavHide={backNavHide}
								showErrandMobileView={!wfui.showErrandMobileView}
								showSideBar={wfui.showSideBar}
								collapseSideBar={wfui.collapseSideBar}
								showMobileView={wfui.showMobileView}
								handleSideBarToggle={this.handleSideBarToggle}
								handleSideBarCollapse={this.handleSideBarCollapse}
								onMainView={this.props.onMainView}
							/>
							<WorkflowInnerContainerCtnr className="app-inner-content" hidden={hide} data-custom-scrollbar>
								<div className="flex-menus-wrap" style={flexMenusStyle}>
									<div className="side-bar-main-menu" hidden={features['hide-cention-logo']}>
										{
											!wfui.collapseSideBar &&
												<div style={{marginRight: "55px"}}><MenuCtnr visible={!wfui.showErrandMobileView} /></div>
										}
										<ToggleSideBar visible={wfui.showSideBar}
											showSideBar={wfui.showSideBar}
											collapsed={wfui.collapseSideBar}
											mobileMode={wfui.showMobileView}
											id={"QA_toggle_sidebar_top"}
											onCollapse={this.handleSideBarCollapse}
											onClick={this.handleSideBarToggle} />
									</div>
									<SubMenuFolder {...this.props} />
									{
										wfui.collapseSideBar &&
											<IconizeSideBarMenuCtnr onChangePage={onChangeAdminView} showWorkflowMenu={true} currentPage={MM_MESSAGE}/>
									}
								</div>
								<SidebarSettingsList>
									<SidebarButtonsList>
										<li>
											<PlusIM
												onClick={this.toggleManualIM}
												collapseSideBar={wfui.collapseSideBar}
												// show={this.props.showIMPopAddMessage}
											/>
										</li>
									</SidebarButtonsList>
								</SidebarSettingsList>
								<SidebarSettingsCtnr
									hideManual={true}
									hidePickupNext={true}
								/>
							</WorkflowInnerContainerCtnr>
						</div>
						<MessageView
							context="internal"
							// type={messageListType}
							data={messagelist}
							onToggleShowSort={onToggleShowSort}
							listTotalCount={listTotalCount}
							totalSelected={totalSelected}
							showSideBar={wfui.showSideBar}
							collapseSideBar={wfui.collapseSideBar}
							loading={loadingMessageList}
							mobileMode={wfui.showMobileView}
							showErrandMobileView={wfui.showErrandMobileView}
							selectedSort={ui.messageListSort}
							tzOffset={wfs.agentTimezoneOffset}
							listReady={wfui.listReady}
							currentSize={listInputs.size}
							currentOffset={listInputs.offset}
							selectedMessages={selectedMessages}

							onClick={this.handleSingleMessageClick}
							onDoubleClick={this.handleDoubleMessageClick}
							onLoadMore={this.handleLoadMore}
							onSortList={this.handleSortTableList}
							onToggleSideBar={this.handleSideBarToggle}

							onSelectAll={this.handleSelectAllMessages}
							onSelectOne={this.handleSelectOneMessage}
							onSelectSort={this.handleSortList}
							// onSwitchToListView={onSwitchToListView}
							onSetLoadList={setLoadList}

							onHandleDeleteMessage={onDeleteMessages}
							onHandleRestoreMessage={onRestoreMessages}
							selectedFolder={filter.selectedFolder}
							showSort={ui.showMessageListSort}
							currentMessageID={currentMessageID}

						/>
						<InternalMessageCtnr
							visible={true}
							// visible={messageListType == "conversation" ? true : (wfui.setErrandFullView ? true : false)}
							// fullView={wfui.setErrandFullView}
							// handleBackToList={this.handleBackToListView}
							name={wfs.activeUserName}
							messagelist={messagelist}
							handleCloseMessageView={this.handleCloseMessageView}
						/>
					</section>
				</div>
			</div>
		)
	}

};

class SubMenuFolder extends Component{
	countMessageByType = () =>{
		let im = 0, drafts = 0, sent = 0, trash = 0;
		let counters = this.props.counters;
		im = counters.imMessage.count;
		drafts = counters.drafts.count;
		sent = counters.sent.count;
		trash = counters.trash.count;
		return{
			interMsg: im ,
			drafts: drafts,
			sent: sent,
			trash:trash
		}
	}
	handleFilterMessages = (p, id) =>{
		const {filterMessagesList} = this.props;
		filterMessagesList(p);
		this.props.filterFolder(id);
	}
	getMenuIcon(id) {
		let icon = "";
		switch(id) {
			case "Internal messages":
				icon = "icon-mail";
				break;
			case "Drafts":
				icon = "icon-mail-draft";
				break;
			case "Sent":
				icon = "icon-sent";
				break;
			case "Trash":
				icon = "icon-trash";
				break;
			default:
				icon = "";
				break;
		}
		return icon;
	}
	render =() =>{
		const {filter, wfui} = this.props;
		const {interMsg, drafts, sent, trash} = this.countMessageByType();
		const mobileView = this.props.wfui.showMobileView, toggleSidebar = this.props.onToggleSideBar;
		return(<div>
			<ul className="sidebar-menu">
				<div>
					<SidebarMenuLi useIcon={wfui.collapseSideBar} data-qa-id="im-folder-msg-inbox" selectedFolder={filter.selectedFolder} msgCounter={interMsg} handleFilterMessages={this.handleFilterMessages} filterType={I('Internal messages')}
						mobileView={mobileView} toggleSidebar={toggleSidebar} folderID={1} />
					<SidebarMenuLi useIcon={wfui.collapseSideBar} data-qa-id="im-folder-drafts" selectedFolder={filter.selectedFolder} msgCounter={drafts} handleFilterMessages={this.handleFilterMessages} filterType={I('Drafts')}
						mobileView={mobileView} toggleSidebar={toggleSidebar} folderID={4} />
					<SidebarMenuLi useIcon={wfui.collapseSideBar} data-qa-id="im-folder-sent" selectedFolder={filter.selectedFolder} msgCounter={sent} handleFilterMessages={this.handleFilterMessages} filterType={I('Sent')}
						mobileView={mobileView} toggleSidebar={toggleSidebar} folderID={2} />
					<SidebarMenuLi useIcon={wfui.collapseSideBar} data-qa-id="im-folder-trash" selectedFolder={filter.selectedFolder} msgCounter={trash} handleFilterMessages={this.handleFilterMessages} filterType={I('Trash')}
						mobileView={mobileView} toggleSidebar={toggleSidebar} folderID={3} />
				</div>
			</ul>
		</div>)
	}
}

class SidebarMenuLi extends Component{
	constructor(props) {
		super(props);
	}
	handleClick = () =>{
		const p = this.props;
		this.props.handleFilterMessages(this.props.filterType, this.props.folderID);
		if(p.mobileView) {
			p.toggleSidebar(false);
		}
	}
	getMenuIcon(id) {
		let icon = "";
		switch(id) {
			case "Internal messages":
				icon = "icon-mail";
				break;
			case "Drafts":
				icon = "icon-mail-draft";
				break;
			case "Sent":
				icon = "icon-sent";
				break;
			case "Trash":
				icon = "icon-trash";
				break;
			default:
				icon = "";
				break;
		}
		return icon;
	}
	render(){
		let imFolderList = "";
		if(this.props.useIcon){
			imFolderList = <SidebarIconItem
				id={this.props.filterType === "Internal messages" ? "InternalMessages" : this.props.filterType}
				icon={this.getMenuIcon(this.props.filterType)}
				data-qa-id={this.props["data-qa-id"]}
				className={this.props.selectedFolder == this.props.folderID ? "current" : ""}
				title={this.props.filterType + "("+ this.props.msgCounter + ")"}
				onClick={this.handleClick}
			/>;
		}else{
			imFolderList = <li data-qa-id={this.props["data-qa-id"]} className={this.props.selectedFolder == this.props.folderID ? "current" : ""} onClick={this.handleClick} key="im"><a>{this.props.filterType}({this.props.msgCounter})</a></li>;
		}
		return imFolderList;
	}
}
export default InternalMessagesList;
