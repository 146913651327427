import React from 'react';
import CHelper from '../../../../components/CHelper';
import { SaveButton, CancelButton } from '../../../../reactcomponents/Admin';
import Helper from '../../../../reactcomponents/Helper';
class CategoryEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			warningText: "",
			data: {
				id: 0,
				name: ''
			},
			firstRender: true
		}
	}
	componentDidUpdate(prevProps) {
		let renderTime = this.state.firstRender;
		if (renderTime === true) {
			if (this.props.data == prevProps.data) {
				renderTime = false;
				this.setState({
					data: this.props.data,
					firstRender: renderTime
				});
			}
		}
		if (this.props.data != prevProps.data) {
			this.setState({ data: this.props.data });
		}
	}
	handleChange = (event) => {
		var state = this.state;
		state.data[event.target.name] = event.target.value;
		state.warningText = "";
		this.setState(state);
	}
	handleSave = () => {
		if (this.state.data["name"] == "") {
			this.setState({ warningText: I("* You cannot leave the Name field empty.") });
			return
		}
		if (this.props.handleSave != undefined) {
			this.props.handleSave(this.state.data);
		}
	}
	render() {
		var catName = " ";
		if (this.state.data && typeof (this.state.data.name) !== 'undefined') {
			catName = this.state.data.name;
		}
		return (
			<div className="card">
				<div className="card-body">
					<div className="form-group">
						<label className="col-lg-2 kb-label">{I('Name')}</label>
						<div className="col-lg-8">
							<input type="text" name="name" value={catName} onChange={this.handleChange} className="form-control form-control-sm"/>
						</div>
						<div className="col-lg-1" style = {{display: "inline-block", marginTop: "5px", marginLeft: "-29px"}}>
							<Helper>{I("The name of the category.")}</Helper>
						</div>
						<div className="row">
							<div style={{ color: 'red', marginLeft: '15px' }} className="help-block with-errors-name">{this.state.warningText}</div>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group editPageActionButton">
						<div className="col-lg-12 textAlignRight">
							<CancelButton style={{fontSize:"12px",marginRight:"10px"}} onClick={this.props.onCancel}/>
							<SaveButton
								key="btn-save-admin"
								onClick={this.handleSave}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CategoryEdit;
