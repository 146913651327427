import React from 'react';
import addonUpdate from 'react-addons-update';
import Calendar from '../../../../components/Calender';
import FileUploader from '../../../../components/FileUploader';
import LibraryCkeditor from '../../../../components/LibraryCkeditor';
import Ckeditor from '../../../../components/v5/Ckeditor';
import CFlag from '../../../../reactcomponents/CFlag';
import { stripHTML, getChevronIcon } from '../../../../common/v5/helpers';
import {
	ActionDropdown2,
} from '../../../../reactcomponents/Dropdown';
import update from 'react-addons-update';
import { SaveButton, CancelButton } from '../../../../reactcomponents/Admin';
import ToolbarButtonIcon from '../../../../reactcomponents/ToolbarButtonIcon';
import Assist, {
	AssistBarLabel
	, AssistColumn
	, AssistBarItems
	, AssistBarOneItem
	, TemplateAssist
} from '../../../../reactcomponents/Assist';
import SwitchCheckbox from '../../../../reactcomponents/SwitchCheckbox';
import Helper from '../../../../reactcomponents/Helper';
class LibraryQuestionAttachment extends React.Component {
	constructor(props) {
		super(props);
	}
	handleRemoveClick = () => {
		if (this.props.onRemove) {
			this.props.onRemove(this.props.id);
		}
	}
	humanSize = (size) => {
		if (size < 1000000)
			return size = (size / 1000).toFixed(2) + ' kb';
		return (size / 1000000).toFixed(2) + ' mb';
	}
	render() {
		var style = {
			marginBottom: '5px'
		};
		if (!this.props.show) {
			style.display = 'none';
		}
		return (
			<div style={style}>
				<span style={{ marginRight: '5px' }}><i className="fa fa-file"></i></span>
				<span style={{ marginRight: '5px' }}><a className="underlink" href={this.props.url}>{this.props.name}</a></span>
				<span style={{ marginRight: '5px' }}>({this.humanSize(this.props.size)})</span>
				{
					!this.props.readOnly &&
					<span style={{ marginRight: '5px' }}><i className="fas fa-trash-alt" onClick={this.handleRemoveClick}></i></span>
				}
			</div>
		);
	}
};

LibraryQuestionAttachment.defaultProps = {
	name: 'libraryQuestion',
	show: false,
	showSave: true,
	showAccept: false,
	showReject: false,
	id: 0,
	name: '',
	size: '',
	url: '',
	readOnly: false
}

export class LibraryQuestionAttachmentList extends React.Component {
	constructor(props) {
		super(props);
	}
	componentWillMount() {
		this.setState({
			'attachments': this.props.attachments
		});
	}
	componentDidUpdate(prevProps) {
		if (this.props.attachments !== prevProps.attachments) {
			this.setState({ 'attachments': this.props.attachments })
		}
	}
	handleFileUploadStart = () => {
		this.setState({
			'uploading': true
		});
	}
	handleFileUploadEnd = (file) => {
		var attachments = addonUpdate(this.state.attachments, {
			$push: [{
				id: file.id,
				name: file.value,
				size: file.estSize,
				url: file.download,
				add: true
			}]
		});
		this.setState({
			uploading: false,
			attachments: attachments
		});
		if (this.props.onChange) {
			this.props.onChange(attachments);
		}
	}
	handleAttachmentRemove = (id) => {
		var attachments = [];
		for (var i = 0; i < this.state.attachments.length; i++) {
			var attachment = this.state.attachments[i];
			if (attachment.id == id) {
				attachment.add = false;
				attachment.remove = true;
				attachments.push(attachment);
			} else {
				attachments.push(attachment);
			}
		}
		this.setState({
			attachments: attachments
		});
		if (this.props.onChange) {
			this.props.onChange(attachments);
		}
	}
	render() {
		if (this.state.attachments && this.state.attachments.length > 0) {
			var attachments = this.state.attachments.map(function (attachment, i) {
				var key = 'library_attachment_' + attachment.id;
				var show = (attachment.remove ? false : true);
				return (
					<LibraryQuestionAttachment
						key={key}
						id={attachment.id}
						name={attachment.name}
						size={attachment.size}
						url={attachment.url}
						show={show}
						readOnly={this.props.readOnly}
						onRemove={this.handleAttachmentRemove}
					/>
				);
			}.bind(this));
		}
		var progressStyle = {};
		if (!this.state.uploading) {
			progressStyle.display = 'none';
		}
		return (
			<div style={{marginRight: '20px'}}>
				<div className="col-lg-10" style={{ paddingLeft: '0' }}>
					{attachments}
					<div style={progressStyle}><img src="img/loading-attachment.gif" /></div>
				</div>
				{
					!this.props.readOnly &&
					<div className="col-lg-2 textAlignRight" style={{ paddingInlineEnd: '0px', float: 'right', marginRight: '0px'}}>
						<FileUploader
							className={'btn btn-light btn-sm'}
							title={'Browse'}
							uploadTo="errand/uploadAnswerAttachment"
							attachFile={true}
							multipleFile={true}
							onProgress={this.handleFileUploadStart}
							onFileupload={this.handleFileUploadEnd}
						/>
					</div>
				}
			</div>
		);
	}
};

LibraryQuestionAttachmentList.defaultProps = {
	attachments: []
	, readOnly: false
}

const FOLLOWUP_TEMPLATE_START = "[FOLLOWUP:";
const FOLLOWUP_TEMPLATE_END = ":FOLLOWUP]";

const emptyTemplateButton = {
	type: "postback",
	title: "",
	data: ""
}, emptyFollowupTemplate = {
	type: "button",
	text: "",
	buttons: [
		emptyTemplateButton
	]
};

class LibraryQuestionEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				id: this.getRandomId(),
				external: false,
				forBot: false,
				subject: '',
				question: '',
				answer: '',
				showInTopList: false,
				timeControlled: false,
				startDate: this.getToday(),
				endDate: this.getToday(),
				keyboardShortcut: '',
				keyboardShortcutUseCtrl: false,
				keyboardShortcutUseAlt: false,
				keyboardShortcutUseShift: false,
				keyboardShortcutUseMeta: false,
				generateTemplate: false,
			},
			fontFamily: '',
			fontSize: '',
			warningText: '',
			spellCheckLanguages: [],
			fileArchiveImages: [],
			keyboardShortcutAlreadyInUse: false,
			attachments: [],
			templateData: emptyFollowupTemplate,
			showArchiveDD: false,
			firstRender: true,
			tab: 0
		}
		this.baseState = this.state;
		this.onSelectArchives = this.onSelectArchives.bind(this);
		this.handleActionToggling = this.handleActionToggling.bind(this);
		this.isWithinSizeLimit = this.isWithinSizeLimit.bind(this);
	}
	getRandomId = () => {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
		for (var i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}
	componentDidUpdate(prevProps) {
		let renderTime = this.state.firstRender;
		if (renderTime === true) {
			if (this.props.data == prevProps.data) {
				renderTime = false;
				if (this.props.data.id && this.props.data.name === undefined) {
					return;
				}
				if (Object.keys(this.props.data).length > 0) {
					var answer = this.props.data.answer;
					var templateData = emptyFollowupTemplate;
					var generateTemplate = false;
					var text = this.props.data.answer;
					if (typeof text != "undefined") {
						var sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
						var eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
						// assuming only one template data
						if (sidx != -1 && eidx != -1 && eidx > sidx) {
							answer = text.substring(0, sidx) + text.substring(eidx + FOLLOWUP_TEMPLATE_END.length, text.length)

							// Get template
							text = stripHTML(this.props.data.answer);
							sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
							eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
							if (sidx != -1 && eidx != -1 && eidx > sidx) {
								var data = text.substring(sidx + FOLLOWUP_TEMPLATE_START.length, eidx).replace(/\s+/g, " ");
								if (data != "") {
									generateTemplate = true;
									templateData = JSON.parse(data);
									templateData.buttons.push(emptyTemplateButton);
								}
							}
						}
					}
					this.setState({
						data: {
							id: this.props.data.id,
							external: this.props.data.external,
							forBot: this.props.data.forBot,
							subject: this.props.data.name,
							question: this.props.data.question,
							answer: answer,
							showInTopList: this.props.data.showInTopList,
							timeControlled: this.props.data.timeControlled,
							generateTemplate: generateTemplate,
							startDate: (this.props.data.startDate),
							endDate: (this.props.data.endDate),
							keyboardShortcut: this.props.data.keyboardShortcut,
							keyboardShortcutUseCtrl: this.props.data.keyboardShortcutUseCtrl,
							keyboardShortcutUseAlt: this.props.data.keyboardShortcutUseAlt,
							keyboardShortcutUseShift: this.props.data.keyboardShortcutUseShift,
							keyboardShortcutUseMeta: this.props.data.keyboardShortcutUseMeta
						},
						templateData: templateData,
						firstRender: renderTime
					});
				} else {
					this.setState({
						data: {
							id: this.getRandomId(),
							external: false,
							forBot: false,
							subject: '',
							question: '',
							answer: '',
							showInTopList: false,
							timeControlled: false,
							generateTemplate: false,
							startDate: this.getToday(),
							endDate: this.getToday(),
							keyboardShortcut: '',
							keyboardShortcutUseCtrl: false,
							keyboardShortcutUseAlt: false,
							keyboardShortcutUseShift: false,
							keyboardShortcutUseMeta: false
						},
						templateData: emptyFollowupTemplate,
						firstRender: renderTime
					});
				}
			}
			if (this.props.data.attachments == prevProps.data.attachments) {
				if (this.props.data.attachments) {
					this.setState({ attachments: this.props.data.attachments });
				} else {
					this.setState({ attachments: [] });
				}
			}
		}
		if (this.props.data != prevProps.data) {
			if (this.props.data.id && this.props.data.name === undefined) {
				return;
			}
			if (Object.keys(this.props.data).length > 0) {
				var answer = this.props.data.answer;
				var templateData = emptyFollowupTemplate;
				var generateTemplate = false;
				var text = this.props.data.answer;
				if (typeof text != "undefined") {
					var sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
					var eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
					// assuming only one template data
					if (sidx != -1 && eidx != -1 && eidx > sidx) {
						answer = text.substring(0, sidx) + text.substring(eidx + FOLLOWUP_TEMPLATE_END.length, text.length)

						// Get template
						text = stripHTML(this.props.data.answer);
						sidx = text.indexOf(FOLLOWUP_TEMPLATE_START);
						eidx = text.indexOf(FOLLOWUP_TEMPLATE_END);
						if (sidx != -1 && eidx != -1 && eidx > sidx) {
							var data = text.substring(sidx + FOLLOWUP_TEMPLATE_START.length, eidx).replace(/\s+/g, " ");
							if (data != "") {
								generateTemplate = true;
								templateData = JSON.parse(data);
								templateData.buttons.push(emptyTemplateButton);
							}
						}
					}
				}
				this.setState({
					data: {
						id: this.props.data.id,
						external: this.props.data.external,
						forBot: this.props.data.forBot,
						subject: this.props.data.name,
						question: this.props.data.question,
						answer: answer,
						showInTopList: this.props.data.showInTopList,
						timeControlled: this.props.data.timeControlled,
						generateTemplate: generateTemplate,
						startDate: (this.props.data.startDate),
						endDate: (this.props.data.endDate),
						keyboardShortcut: this.props.data.keyboardShortcut,
						keyboardShortcutUseCtrl: this.props.data.keyboardShortcutUseCtrl,
						keyboardShortcutUseAlt: this.props.data.keyboardShortcutUseAlt,
						keyboardShortcutUseShift: this.props.data.keyboardShortcutUseShift,
						keyboardShortcutUseMeta: this.props.data.keyboardShortcutUseMeta
					},
					templateData: templateData
				});
			} else {
				this.setState({
					data: {
						id: this.getRandomId(),
						external: false,
						forBot: false,
						subject: '',
						question: '',
						answer: '',
						showInTopList: false,
						timeControlled: false,
						generateTemplate: false,
						startDate: this.getToday(),
						endDate: this.getToday(),
						keyboardShortcut: '',
						keyboardShortcutUseCtrl: false,
						keyboardShortcutUseAlt: false,
						keyboardShortcutUseShift: false,
						keyboardShortcutUseMeta: false
					},
					templateData: emptyFollowupTemplate
				});
			}
		}
		if (this.props.data.attachments != prevProps.data.attachments) {
			if (this.props.data.attachments) {
				this.setState({ attachments: this.props.data.attachments });
			} else {
				this.setState({ attachments: [] });
			}
		}
	}
	handleChangeTemplateButton = (index, event) => {
		var state = update(this.state, {
			templateData: {
				buttons: {
					[index]: { [event.target.name]: { $set: event.target.value } }
				}
			}
		});
		if (index == state.templateData.buttons.length - 1 &&
			state.templateData.buttons[index].title != '' &&
			state.templateData.buttons[index].data != '') {
			state = update(state, {
				templateData: { buttons: { $push: [emptyTemplateButton] } }
			});
		}
		this.setState(state);
	}
	handleDeleteTemplateButton = (index) => {
		var state = update(this.state, {
			templateData: { buttons: { $splice: [[index, 1]] } }
		})
		var entry = state.templateData.buttons.length;
		if (entry == 0 ||
			(state.templateData.buttons[entry - 1].title != '' &&
				state.templateData.buttons[entry - 1].data != '')) {
			state = update(state, {
				templateData: { buttons: { $push: [emptyTemplateButton] } }
			});
		}
		this.setState(state);
	}
	handleChangeFollowupQuestion = (event) => {
		var state = update(this.state, { templateData: { text: { $set: event.target.value } } });
		this.setState(state);
	}
	handleChange = (event) => {
		var state = this.state;
		if (event.target.type == 'text') {
			state.data[event.target.name] = event.target.value;
		} else if (event.target.type == 'checkbox') {
			state.data[event.target.name] = event.target.checked;
		}
		this.setState(state);
	}
	updateStateData = (name, value) => {
		var update = {};
		update[name] = { $set: value };
		var data = addonUpdate(this.state.data, update);
		this.setState({
			data: data
		});
	}
	handleCkeditorChange = (event) => {
		var name = event.editor.name.substring((this.props.name + '_').length);
		this.updateStateData(name, event.editor.getData());
		this.setState({ warningText: "" });
	}
	handleStartDateChange = (value) => {
		this.updateStateData('startDate', value);
	}
	handleEndDateChange = (value) => {
		this.updateStateData('endDate', value);
	}
	handleAttachmentListChange = (list) => {
		this.setState({ attachments: list })
		this.forceUpdate();
	}
	handleExternalChange = (event) => {
		var external = event.target.checked;
		var showInTopList = this.state.data.showInTopList;
		// if (!external) {
		// 	showInTopList = false;
		// }
		this.setState({
			'data': addonUpdate(this.state.data, {
				external: { $set: external },
				showInTopList: { $set: showInTopList }
			})
		});
	}

	handleExternalToggle = (checked) => {
		var external = !checked;
		var showInTopList = this.state.data.showInTopList;
		// if (!external) {
		// 	showInTopList = false;
		// }
		this.setState({
			'data': addonUpdate(this.state.data, {
				external: { $set: external },
				showInTopList: { $set: showInTopList }
			})
		});
	}

	handleTopToggle = (checked) => {
		var showInTopList = !checked;

		this.setState({
			'data': addonUpdate(this.state.data, {
				showInTopList: { $set: showInTopList }
			})
		});
	}

	handleTimeToggle = (checked) => {
		var timeControlled = !checked;

		this.setState({
			'data': addonUpdate(this.state.data, {
				timeControlled: { $set: timeControlled }
			})
		});
	}

	handleFollowToggle = (checked) => {
		var generateTemplate = !checked;

		this.setState({
			'data': addonUpdate(this.state.data, {
				generateTemplate: { $set: generateTemplate }
			})
		});
	}

	handleKeyboardToggle = (checked) => {
		var generateTemplate = !checked;

		this.setState({
			'data': addonUpdate(this.state.data, {
				generateTemplate: { $set: generateTemplate }
			})
		});
	}

	handleKeyboartShortcutModifierChange = (event) => {
		var update = {};
		update[event.target.name] = { $set: event.target.checked };
		this.setState({
			data: addonUpdate(this.state.data, update)
		});
		if (this.state.data.keyboardShortcut.length > 0) {
			var parameters = {
				ctrl: this.state.data.keyboardShortcutUseCtrl,
				alt: this.state.data.keyboardShortcutUseAlt,
				shift: this.state.data.keyboardShortcutUseShift,
				meta: this.state.data.keyboardShortcutUseMeta,
				keys: this.state.data.keyboardShortcut
			};
			var name = event.target.name.substring(('keyboardShortcutUse').length).toLowerCase();
			parameters[name] = event.target.checked;
			this.checkKeyboardShortcutUsage(parameters);
		}
	}
	handleKeyboardShortcutChange = (event) => {
		this.setState({
			data: addonUpdate(this.state.data, {
				keyboardShortcut: { $set: event.target.value }
			})
		});
		if (event.target.value.length > 0) {
			this.checkKeyboardShortcutUsage({
				ctrl: this.state.data.keyboardShortcutUseCtrl,
				alt: this.state.data.keyboardShortcutUseAlt,
				shift: this.state.data.keyboardShortcutUseShift,
				meta: this.state.data.keyboardShortcutUseMeta,
				keys: event.target.value
			});
		}
	}
	checkKeyboardShortcutUsage = (parameters) => {
		var url = webRoot + 'admin/library/question/shortcut/usage';
		parameters.ignore = this.state.data.id;
		$.get(url, parameters).then(function (response) {
			this.setState({
				keyboardShortcutAlreadyInUse: response.usage
			});
		}.bind(this));
	}
	handleSave = () => {
		if (this.state.keyboardShortcutAlreadyInUse) {
			return;
		}
		if (this.props.handleSave != undefined) {
			var addAttachments = [];
			var removeAttachments = [];
			if (stripHTML(this.state.data.question).trim() == "") {
				this.setState({ warningText: "* You cannot leave the Question field empty." });
				return;
			}
			if (stripHTML(this.state.data.answer).trim() == "") {
				this.setState({ warningText: "* You cannot leave the Answer field empty." });
				return;
			}
			if (this.state.attachments) {
				for (var i = 0; i < this.state.attachments.length; i++) {
					var attachment = this.state.attachments[i];
					if (attachment.remove) {
						removeAttachments.push(attachment.id)
					} else if (attachment.add) {
						addAttachments.push(attachment.id);
					}
				}
			}
			var data = addonUpdate(this.state.data, {
				addAttachments: { $set: addAttachments.join(',') },
				removeAttachments: { $set: removeAttachments.join(',') }
			});
			if (this.state.data.generateTemplate) {
				if (this.state.templateData.text == "") {
					this.setState({ warningText: "* You cannot leave the Folloup question field empty." });
					return;
				}
				var validButton = true;
				var buttons = this.state.templateData.buttons;
				for (var i = 0; i < buttons.length; i++) {
					if ((buttons[i].title == "" && buttons[i].data != "") ||
						(buttons[i].title != "" && buttons[i].data == "")) {
						validButton = false;
						break;
					}
				}
				if (!validButton) {
					this.setState({ warningText: "* You cannot leave the template button field empty." });
					return;
				}
				var templateData = update(this.state.templateData, { buttons: { $splice: [[buttons.length - 1, 1]] } })
				data = update(data, {
					answer: {
						$set: data.answer + FOLLOWUP_TEMPLATE_START +
							JSON.stringify(templateData) + FOLLOWUP_TEMPLATE_END
					}
				}
				);
			}
			this.props.handleSave(data);
		}
	}
	handleReject = () => {
		if (this.props.onReject != undefined) {
			this.props.onReject(this.state.data.id);
		}
	}
	handleDragnDropFile = (fileData, fileInfo) => {
		if (this.props.onDragnDropFile) {
			return this.props.onDragnDropFile(fileData, fileInfo)
		}
	}
	handleKeyDown = () => {
	}
	handleFileDrop = (file) => {
		var attachments = addonUpdate(this.state.attachments, {
			$push: [{
				id: file.id,
				name: file.value,
				size: file.estSize,
				url: file.download,
				add: true
			}]
		});
		this.setState({ attachments: attachments });
	}
	handleAppendTemplate = (id, ob) => {
		let tmplName = '[' + ob.Name + ']'
		this.props.onAppendTemplate(tmplName);
	}

	handleActionToggling = () => {
		let showArchiveDD = !this.state.showArchiveDD;
		this.setState({ showArchiveDD: showArchiveDD });
	}

	handleTabClick = (event) => {
		var tab = parseInt(event.target.parentNode.id.substring(12));
		this.setState({
			tab: tab
		});
	};

	isWithinSizeLimit = (estimatedSize) => {
		if (typeof this.props.maxFileAllowed === 'undefined' || this.props.maxFileAllowed <= 0) {
			return true // don't care about the file limits
		}
		if (estimatedSize > this.props.maxFileAllowed) {
			return false;
		}
		return true;
	}

	onSelectArchives(selectedId) {
		// let filesArchive = this.props.fileArchiveImages;
		// if(filesArchive && filesArchive.files && filesArchive.files.length > 0){
		// 	for(let i=0; i < filesArchive.files.length; i++){
		// 		if(filesArchive.files[i].id == selectedId){
		// 			alert(I("This archive file has already been selected"));
		// 			return;
		// 		}

		// 	};
		// }
		let uploadTo = "errand/uploadAnswerAttachment";
		let list = [];
		let ifexist = false;
		let ifadded = false;
		if (this.props.fileArchiveImages.length > 0) {
			for (var i = 0; i < this.props.fileArchiveImages.length; i++) {
				let fileArchiveImages = this.props.fileArchiveImages[i];
				if (selectedId == fileArchiveImages.id) {
					if (this.state.attachments.length > 0) {
						for (var j = 0; j < this.state.attachments.length; j++) {
							if (selectedId == this.state.attachments[j].id) {
								ifadded = this.state.attachments[j].add;
								ifexist = true;
							}
						}
						if (!ifexist || !ifadded) {
							list = this.state.attachments;
							const temp = { id: selectedId, name: fileArchiveImages.name, url: fileArchiveImages.src, size: fileArchiveImages.size, add: true, remove: false };
							list.push(temp);
						} else {
							list = this.state.attachments;
							alert("This image has already been selected");
						}
					} else {
						list = this.state.attachments;
						const temp = { id: selectedId, name: fileArchiveImages.name, url: fileArchiveImages.src, size: fileArchiveImages.size, add: true, remove: false };
						list.push(temp);
					}
				}
			}
		}
		this.setState({ attachments: list })
		// this.props.onUploadArchive(selectedId, this.props.archiveImgs,
		// 	this.props.showAttachment, this.props.currentReply, this.props.chat);
	}

	templateForm = () => {
		var buttons = [];
		var datas = this.state.templateData.buttons;
		datas.forEach((el, idx) => {
			buttons.push(
				<div className="form-group row" key={"template_button_" + idx}>
					<label className="col-lg-6 col-form-label">{I('Button') + ' ' + (idx + 1)}</label>
					<label className="col-lg-6 col-form-label">{I('Data') + ' ' + (idx + 1)}</label>
					<div className="col-lg-5">
						<table>
							<tr>
								<td style={{ width: '100%' }}>
									<input
										type="text"
										name="title"
										className="form-control form-control-sm"
										autoComplete="off"
										onChange={this.handleChangeTemplateButton.bind(this, idx)}
										value={el.title}
									/>
								</td>
							</tr>
						</table>
					</div>
					<div className="col-lg-1"></div>
					<div className="col-lg-5">
						<table>
							<tr>
								<td style={{ width: '100%' }}>
									<input
										type="text"
										name="data"
										className="form-control form-control-sm"
										autoComplete="off"
										onChange={this.handleChangeTemplateButton.bind(this, idx)}
										value={el.data}
									/>
								</td>
							</tr>
						</table>
					</div>
					<div className="col-lg-1">
						<button className="btn btn-xs dropdown-toggle"
							onClick={this.handleDeleteTemplateButton.bind(this, idx)}
							type="button">
							<i className="fa fa-trash-o"></i>
						</button>
					</div>
					<div className="clear"></div>
				</div>
			);
		});
		return (
			<div>
				<div className="form-group row">
					<label className="col-lg-3 col-form-label" style={{ display: 'flex' }}>{I('Question')}</label>
					<div className="col-lg-7">
						<input
							type="text"
							name="text"
							className="form-control form-control-sm"
							autoComplete="off"
							onChange={this.handleChangeFollowupQuestion}
							value={this.state.templateData.text}
						/>
					</div>
					<div className="clear"></div>
				</div>
				{buttons}
			</div>
		)
	}
	render() {
		const { showArchiveDD, tab } = this.state;
		var tabs = [], numTabs = 4, templateTabs = false, disable = false;
		for (var i = 0; i < numTabs; i++) {
			var tabClass = '';
			var contentStyle = { display: 'none' };
			if (i == tab) {
				tabClass = 'active';
				contentStyle = {};
				templateTabs = false;
			}
			if (tab == 2) {
				templateTabs = true;
			}
			tabs.push({ tabClass: tabClass, contentStyle: contentStyle });
		};
		var keyboardShortcutWarningStyle = {
			color: '#d9534f',
			paddingLeft: '8px',
			fontSize: '14px',
			display: 'none',
			cursor: 'default'
		};
		if (this.state.keyboardShortcutAlreadyInUse) {
			delete keyboardShortcutWarningStyle.display;
		}
		var topListStyle = {
			display: 'none'
		};
		if (this.state.data.external) {
			delete (topListStyle.display);
		}
		var saveStyle = {};
		var acceptStyle = {};
		var rejectStyle = {};
		if (!this.props.showSave) {
			saveStyle.display = 'none';
		}
		if (!this.props.showAccept) {
			acceptStyle.display = 'none';
		}
		if (!this.props.showReject) {
			rejectStyle.display = 'none';
		}
		var subject = " ";
		var checkState = false;
		var forBotState = false;
		var showInTopList = false;
		var timeControlled = false;
		var keyboardShortcutUseCtrl = false;
		var keyboardShortcutUseAlt = false;
		var keyboardShortcutUseShift = false;
		var keyboardShortcutUseMeta = false;
		var keyboardShortcut = false;
		var generateTemplate = false;
		var hideKeyboardShortcuts = true;
		var ctrlSelected = "", altSelected = "", shiftSelected = "", commandSelected = "", keySelected = "";
		if (typeof (this.state.data.subject) !== 'undefined') {
			subject = this.state.data.subject;
		}
		if (typeof (this.state.data.external) !== 'undefined') {
			checkState = this.state.data.external;
		}
		if (typeof (this.state.data.forBot) !== 'undefined') {
			forBotState = this.state.data.forBot;
		}
		if (typeof (this.state.data.showInTopList) !== 'undefined') {
			showInTopList = this.state.data.showInTopList;
		}
		if (typeof (this.state.data.timeControlled) !== 'undefined') {
			timeControlled = this.state.data.timeControlled;
		}
		if (typeof (this.state.data.keyboardShortcutUseCtrl) !== 'undefined') {
			keyboardShortcutUseCtrl = this.state.data.keyboardShortcutUseCtrl;
			ctrlSelected = keyboardShortcutUseCtrl ? "active" : "";
		}
		if (typeof (this.state.data.keyboardShortcutUseAlt) !== 'undefined') {
			keyboardShortcutUseAlt = this.state.data.keyboardShortcutUseAlt;
			altSelected = keyboardShortcutUseAlt ? "active" : "";
		}
		if (typeof (this.state.data.keyboardShortcutUseShift) !== 'undefined') {
			keyboardShortcutUseShift = this.state.data.keyboardShortcutUseShift;
			shiftSelected = keyboardShortcutUseShift ? "active" : "";
		}
		if (typeof (this.state.data.keyboardShortcutUseMeta) !== 'undefined') {
			keyboardShortcutUseMeta = this.state.data.keyboardShortcutUseMeta;
			commandSelected = keyboardShortcutUseMeta ? "active" : "";
		}
		if (typeof (this.state.data.keyboardShortcut) !== 'undefined') {
			keyboardShortcut = this.state.data.keyboardShortcut;
			keySelected = keyboardShortcut ? "containKey form-control form-control-sm" : "form-control form-control-sm";
		}
		if (typeof (this.state.data.generateTemplate) !== 'undefined') {
			generateTemplate = this.state.data.generateTemplate;
		}
		var newHelperStyle =
		{
			display: "inline-block",
			marginLeft: "-20px",
			marginTop: "3px"
		};
		var newHelperPos = {
			display: "inline-block",
			marginLeft: "-30px",
			marginTop: "3px"
		};
		hideKeyboardShortcuts = this.props.showKeyboardShortcuts;
		var conversationBot = null;
		if (!this.props.noForBot) {
			conversationBot = <div className="form-group row">
				<label className="col-lg-2 col-form-label">{I('Automated Conversation Bot')}</label>
				<div className="col-lg-7">
					<input
						name="forBot"
						type="checkbox"
						checked={forBotState}
						onChange={this.handleChange}
					/>
					<span className="checkboxLabel"></span>
				</div>
				<div className="col-lg-2">
					<Helper>{I("Check this box if the question will be available for automated conversation bot.")}</Helper>
				</div>
				<div className="clear"></div>
			</div>
		}
		return (
			<div className="card">
				<div className="card-body">
					<div className="form-group row">
						<label className="col-lg-2 col-form-label" style={{ display: 'flex' }}>{I('Subject')}</label>
						<div className="col-lg-9">
							<input
								type="text"
								name="subject"
								className="form-control form-control-sm"
								autoComplete="off"
								onChange={this.handleChange}
								value={subject}
							/>
						</div>
						<div className="col-lg-1" style={{display: "block",marginTop: "4px", marginLeft: "-30px"}}>
							<Helper>{I("The subject of the question. This is what will be displayed in the tree.")}</Helper>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row">
						<label className="col-lg-2 col-form-label" style={{ display: 'flex' }}>{I('Question')}</label>
						<div className="clear"></div>
						<div className="col-lg-9">
							<Ckeditor
								id={this.props.name + '_question'}
								name={this.props.name + '_question'}
								data-qa-id='QA_KBQuestionEditor'
								toolbarID={'OneRow'}
								defaultFontFamily={this.state.fontFamily}
								defaultFontSize={this.state.fontSize}
								spellLanguages={this.state.spellCheckLanguages}
								defaultContent={this.state.data.question}
								hideSpellchecker={false}
								showResize={true}
								resizeMinHeight={100}
								height={100}
								verticalView={true}
								fileArchiveImages={this.props.fileArchiveImages}
								onKeydown={this.handleKeyDown}
								onChange={this.handleCkeditorChange}
								onDragnDropFiles={this.handleDragnDropFile}
								onFileDrop={this.handleFileDrop}
								defShowYoutube={true}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row">
						<label className="col-lg-2 col-form-label">{I('Answer')}</label>

						<div className='col-lg-10 col-form-label answer-assist' style={{ display: "flex" }}>
							<div style={{ paddingRight: "5px" }}>{I('Assist:')}</div>
							<TemplateAssist
								id={"kb-template-assist"}
								data-qa-id='QA_KBTemplateAssist'
								data={this.props.templates}
								onAppendItem={this.handleAppendTemplate}
								direction="down"
							/>
						</div>
						<div className="clear"></div>
						<label className="col-lg-2 col-form-label" style={{ display: 'flex' }}></label>
						<div className="col-lg-9">
							<Ckeditor
								id={this.props.name + '_answer'}
								name={this.props.name + '_answer'}
								data-qa-id='QA_KBAnswerEditor'
								toolbarID={'OneRow'}
								defaultFontFamily={this.state.fontFamily}
								defaultFontSize={this.state.fontSize}
								spellLanguages={this.state.spellCheckLanguages}
								defaultContent={this.state.data.answer}
								hideSpellchecker={false}
								showResize={true}
								resizeMinHeight={100}
								height={100}
								verticalView={true}
								fileArchiveImages={this.props.fileArchiveImages}
								insertionText={this.props.insertionText}
								onResetInsertionText={this.props.onResetInsertionText}
								onKeydown={this.handleKeyDown}
								onChange={this.handleCkeditorChange}
								onDragnDropFiles={this.handleDragnDropFile}
								onFileDrop={this.handleFileDrop}
								defShowYoutube={true}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row">
						<label className="col-lg-2 col-form-label" style={{ display: 'flex' }}>{I('Attachments')}</label>
						<div className="col-lg-8" style={{ paddingRight: "8px" }}>
							<LibraryQuestionAttachmentList attachments={this.state.attachments} onChange={this.handleAttachmentListChange} />
						</div>
						<div className="attachFileArchive">
							<ActionDropdown2 id="archiveFilesKnowledgeB"
								title={<span className="archivefile">
									<a>{I("Choose File")}
										<i className={getChevronIcon(!showArchiveDD)} />
									</a>
								</span>}
								show={showArchiveDD}
								isHeader={true}
								headerText={I("Image archive")}
								direction={"up"}
								data={this.props.fileArchiveImages}
								onToggle={this.handleActionToggling}
								onSelectItem={this.onSelectArchives} />
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group row">
						<label className="col-lg-2 col-form-label" style={{ display: 'flex' }}>{I('External')}</label>
						<div className="col-lg-1 col-form-label">
							{/* <input
								name="external"
								type="checkbox"
								checked={checkState}
								onChange={this.handleExternalChange}
							/>
							<span className="checkboxLabel"></span> */}

							<SwitchCheckbox
								active={checkState}
								buttonClassName={""}
								id={"knowledgeBaseExternal"}
								name={"external"}
								type="checkbox"
								onClick={this.handleExternalToggle} />
						</div>
						<div className="col-lg-2 col-form-label" style={{display: "block", marginLeft: "-22px", marginTop: "3.5px"}}>
							<Helper>{I("Check this box if the question will be available on a web site. This will allow you add things to either the question or the answer which is not seen when including the answer in an email such as embedded YouTube videos.")}</Helper>
						</div>
						<div className="clear"></div>
					</div>
					{conversationBot}
					<hr></hr>
					<div className="questionTabEdit">
						<ul className="nav nav-tabs col-md-12" style={{ borderBottom: "none", marginBottom: "15px" }}>
							<li role="presentation" id="questionTab_0" className="nav-item" data-qa-id="QA_tab_questions">
								<a className={`nav-link ${tabs[0].tabClass}`} href="#library"
									onClick={this.handleTabClick}>{I('Top list')}
								</a>
							</li>
							<li role="presentation" id="questionTab_1" className="nav-item" data-qa-id="QA_tab_library">
								<a className={`nav-link ${tabs[1].tabClass}`} href="#library"
									onClick={this.handleTabClick}>{I('Time controlled')}
								</a>
							</li>
							<li role="presentation" id="questionTab_2" className="nav-item" data-qa-id="QA_tab_category">
								<a className={`nav-link ${tabs[2].tabClass}`} href="#library"
									onClick={this.handleTabClick}>{I('Follow up')}
								</a>
							</li>
							<li role="presentation" id="questionTab_3" className="nav-item" data-qa-id="QA_tab_questions" hidden={!this.props.showKeyboardShortcuts} >
								<a className={`nav-link ${tabs[3].tabClass}`} href="#library"
									onClick={this.handleTabClick}>{I('Keyboard shortcut')}
								</a>
							</li>

						</ul>
						<div className="form-group row" style={topListStyle, tabs[0].contentStyle}>
							<label className="col-lg-3 col-form-label" style={{ display: 'flex' }}>{I('Show In Top List')}</label>
							<div className="col-lg-1 col-form-label">
								<SwitchCheckbox
									active={showInTopList}
									buttonClassName={""}
									id={"knowledgeBaseTopList"}
									name={"TopList"}
									type="checkbox"
									onClick={this.handleTopToggle} />
							</div>
							<div className="col-lg-2 col-form-label" style={{marginLeft: "-20px", marginTop: "3px"}}>
								<Helper>{I("Check this box if you want this question to be shown in the top list.")}</Helper>
							</div>
							<div className="clear"></div>
						</div>
						<div className="form-group row" style={tabs[1].contentStyle}>
							<label className="col-lg-3 col-form-label" style={{ display: 'flex' }}>{I('Time Controlled')}</label>
							<div className="col-lg-1 col-form-label">
								<SwitchCheckbox
									active={timeControlled}
									buttonClassName={""}
									id={"knowledgeBaseTime"}
									name={"Time"}
									type="checkbox"
									onClick={this.handleTimeToggle} />
							</div>
							<div className="col-lg-2 col-form-label" style={newHelperStyle}>
								<Helper>{I("Check this box if you want this question to be time controlled. When a question is time controlled it is only visible between the start and the end date.")}</Helper>
							</div>
							<div className="clear"></div>
						</div>
						<div className="form-group row" style={{ display: (this.state.data.timeControlled ? 'block' : 'none') }, tabs[1].contentStyle}>
							<label className="col-lg-3 col-form-label">{I('Start date')}</label>
							<div className="col-lg-7">
								<Calendar id={this.props.name + '_startDate'} selectedDate={this.state.data.startDate} onChange={this.handleStartDateChange} disable={!this.state.data.timeControlled} />
							</div>
							<div className="col-lg-2">
								<span style={newHelperPos}><Helper>{I("Use this to decide this first day this question should be visible.")}</Helper></span>
							</div>
							<div className="clear"></div>
						</div>
						<div className="form-group row" style={{ display: (this.state.data.timeControlled ? 'block' : 'none') }, tabs[1].contentStyle}>
							<label className="col-lg-3 col-form-label">{I('End date')}</label>
							<div className="col-lg-7">
								<Calendar id={this.props.name + '_endDate'} selectedDate={this.state.data.endDate} onChange={this.handleEndDateChange} disable={!this.state.data.timeControlled} />
							</div>
							<div className="col-lg-2">
								<span style={newHelperPos}><Helper>{I("Use this to decide the last day this question should be visible.")}</Helper></span>
							</div>
							<div className="clear"></div>
						</div>
						<div className="form-group row" style={tabs[2].contentStyle}>
							<label className="col-lg-3 col-form-label" style={{ display: 'flex' }}>{I('Follow up')}</label>
							<div className="col-lg-1 col-form-label">
								<SwitchCheckbox
									active={generateTemplate}
									buttonClassName={""}
									id={"knowledgeBaseFollowUp"}
									name={"FollowUp"}
									type="checkbox"
									onClick={this.handleFollowToggle} />
							</div>
							<div className="col-lg-2 col-form-label" style={newHelperStyle}>
								<Helper>{I("Check this box if you want to add follow up question for this answer.")}</Helper>
							</div>
							<div className="clear"></div>
						</div>
						{generateTemplate && templateTabs && this.templateForm()}
						<div className="form-group row" hidden={!this.props.showKeyboardShortcuts} style={tabs[3].contentStyle}>
							<label className="col-lg-2 col-form-label">{I('Chat shortcut')}</label>
							<div className="col-lg-9 col-form-label keyboardContainer">
								<div className="col-lg-2">
									<label className={ctrlSelected}>
										<input
											name="keyboardShortcutUseCtrl"
											type="checkbox"
											id="ctrl"
											checked={keyboardShortcutUseCtrl}
											onChange={this.handleKeyboartShortcutModifierChange}
										/>Ctrl</label>
									{/* <span className="bold" style={{ verticalAlign: 'super', paddingLeft: '16px' }}>{I('Ctrl Key')}</span> */}
								</div>
								<div className="col-lg-2">
									<label className={altSelected}>
										<input
											name="keyboardShortcutUseAlt"
											type="checkbox"
											id="alt"
											checked={keyboardShortcutUseAlt}
											onChange={this.handleKeyboartShortcutModifierChange}
										/>Alt</label>
									{/* <span className="bold" style={{ verticalAlign: 'super', paddingLeft: '16px' }}>{I('Alt Key')}</span> */}
								</div>
								<div className="col-lg-2">
									<label className={shiftSelected}>
										<input
											name="keyboardShortcutUseShift"
											type="checkbox"
											id="shift"
											checked={keyboardShortcutUseShift}
											onChange={this.handleKeyboartShortcutModifierChange}
										/>Shift</label>
									{/* <span className="bold" style={{ verticalAlign: 'super', paddingLeft: '16px' }}>{I('Shift Key')}</span> */}
								</div>
								<div className="col-lg-3">
									<label className={commandSelected}>
										<input
											name="keyboardShortcutUseMeta"
											type="checkbox"
											id="command"
											checked={keyboardShortcutUseMeta}
											onChange={this.handleKeyboartShortcutModifierChange}
										/>⌘ Command</label>
									{/* <span className="bold" style={{ verticalAlign: 'super', paddingLeft: '16px' }}>{I('⌘ Key')}</span> */}
								</div>
								<div className="col-lg-3">
									<input
										name="keyboardShortcut"
										className={keySelected}
										type="text"
										value={keyboardShortcut}
										onChange={this.handleKeyboardShortcutChange}
										maxLength={1}
										style={{ width: '100%' }}
									/>
									<i className="fa fa-exclamation-triangle"
										style={keyboardShortcutWarningStyle}
										title={I('This combination is already in use by another question.')}></i>
								</div>
							</div>
							<div className="col-lg-1 col-form-label">
								<Helper>{I("Here you can configure a keyboard shortcut which can be used to insert the answer while chatting. Check the modifiers you would like to use and then enter the key in the text box.")}</Helper>
							</div>
							<div className="clear"></div>
						</div>
						<div className="row">
							<div style={{ color: 'red', marginLeft: '15px' }} className="help-block with-errors-name">{this.state.warningText}</div>
						</div>
					</div>
					<div className="form-group row editPageActionButton">
						<div className="col-lg-12 textAlignRight question-button">
							<CancelButton style={{ fontSize: "12px", marginRight: "10px" }} onClick={this.props.onCancel} />
							<SaveButton
								style={{ marginRight: "10px"}}
								key="btn-save-admin"
								onClick={this.handleSave}
							/>
							<span style={rejectStyle}>
								<button
									style={{ marginRight: "10px"}}
									className="btn btn-danger btn-sm"
									type="button"
									onClick={this.handleReject}>{I('Reject')}</button>
							</span>
							<span style={acceptStyle}>
								<button
									style={{ marginRight: "10px"}}
									className="btn btn-primary btn-sm"
									type="button"
									onClick={this.handleSave}>{I('Accept')}</button>
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
	getToday = () => {
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth() + 1;
		var yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		return yyyy + '/' + mm + '/' + dd;
	}
};

LibraryQuestionEdit.defaultProps = {
	attachments: []
}
export default LibraryQuestionEdit;
