import React, {Component} from 'react';
import MenuCtnr from '../../../app/common/v5/menuCtnr';
import ToggleSideBar from '../../components/v5/ToggleSideBar';
import { SidebarSettingsCtnr } from './workflowCtnr';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
	withExport,
	withGeneratedExport,
	withExportInfo,
	withMapExportPreview
} from '../../views/exports/exportCtnr';
import EditExportV5 from '../../views/exports/exportEditV5';
import classNames from 'classnames';
import {
	TableIconicButton
} from '../../reactcomponents/Form';
import {
	TABLE_BASE_CLASSNAME
} from '../../reactcomponents/common';
import {
	getListSizeBasedOnHeight
	, calculateRowsToFitInContainer
	, isValidTelavoxUrl
} from '../../common/v5/helpers';
import {
	MM_EXPORT,
	CENTION_LOGO_MINI,
	CENTION_LOGO
} from '../../common/v5/constants';
import {
	EXPORTS as EXPORT_LINK,
	EXPORTS_CALL as EXPORTS_CALL_LINK
} from '../../common/path';
import { WorkflowBreadcrumbsCtnr, WorkflowInnerContainerCtnr, IconizeSideBarMenuCtnr } from './workflowCtnr';
import { CancellableSimpleLink } from '../../containers/link';
import { isMobile } from '../../redux/util';
import { CheckboxBase } from '../../reactcomponents/SquareCheckbox'

const ExportInfoCtnr = withExportInfo(EditExportV5);

const CheckBoxExport = (props) => {
	const handleCtrlCheckbox = (e) => {
		props.onClick(props.checkId, e.target.checked)
	};

	return <CheckboxBase checked={props.checked} onChange={handleCtrlCheckbox}/>
}

class Exportv5 extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.handleExportPgResize);
		window.addEventListener('message', this.handleMessageEvent);
		if(this.props.onLoad) {
			this.props.onLoad();
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleExportPgResize);
		window.removeEventListener('message', this.handleMessageEvent);
	}

	componentWillMount = () => {
		this.props.fetchExportList();
		this.props.fetchAreas();
	}
	createNew = () => {
		// this.props.onToggleExportPopUp(true)
		this.props.onSetSelectedExport(0)
	}
	rowHighlightHandle = id => {
		this.props.onSetSelectedExport(id)
		// this.props.onToggleExportPopUp(true)
		if(this.props.baseUi.showMobileView) {
			this.props.onToggleExportPopUp(true)
		}
	}

	handleActivate = (id, checked) => {
		this.props.onActivate(id, checked);
	}

	handleRemove = (id, name) => {
		let confirmDelMsg = I('Are you sure you want to delete this Export? {EXPORTNAME}').replace('{EXPORTNAME}', name)
		if (confirm(confirmDelMsg)) {
			this.props.onRemove(id);
			this.props.onCancel();
		}
	}
	handlePreviewClose = (e) => {
		this.props.onPreviewClose()
	}

	handleSideBarToggle = () => {
		this.props.onToggleSideBar(!this.props.baseUi.showSideBar);
	}

	handleSideBarCollapse = () => {
		this.props.onCollapseSideBar(!this.props.baseUi.collapseSideBar);
	}
	handleTogglePopUp = (show) => {
		this.props.onToggleExportPopUp(show)
	}
	handleMobileCreateForm = () => {
		this.props.onSetSelectedExport(0);
		this.props.onToggleExportPopUp(true)
	}

	hanldleRowClick = (row, rowIndex, columnIndex, event ) => {
		switch (event.target.tagName) {
			case "BUTTON":
			case "I":
			case "INPUT":
				break;
			default:
				this.rowHighlightHandle(row.id)
				break;
		}
	}
	renderDeleteButton = (cell, row, enumObject, index) => {
		return <div className="table-btns">
				<TableIconicButton type="button" className="btn-red" iconClass={"far fa-trash-alt"} title={I("Delete")} onClick={e => this.handleRemove(row.id, row.name)}/>
			</div>
	}

	renderCheckBox = (cell, row, formatExtraData, rowIndex) => {
		return <CheckBoxExport onClick={this.handleActivate} checked={row.activate} checkId={row.id}/>
	}

	renderPaginationPanel = (props) => {
		return (
			<div className="wf-pagination">
				<div className="wf-pagination-page">
				{  props.totalPages > 1 && props.components.pageList }
				</div>
				<div className="wf-pagination-current">
					{
						(this.props.tableList && this.props.tableList.length > 0) &&
							I('Showing {CURRENT_PAGE} of {TOTAL_PAGES}')
								.replace('{CURRENT_PAGE}', props.currPage)
								.replace('{TOTAL_PAGES}', props.totalPages)
					}
				</div>
				{/* ToFix: to provide pageSize options or not?
				<div className="wf-pagination-size">
					<PaginationSize size={ERRAND_PAGINATION_SIZE_OPT} sizePerPage={props.sizePerPage} onChangeSizePerPage={props.changeSizePerPage}/>
				</div> */}
			</div>
		);
	}

	handleExportPgResize = () => {
		if(isMobile) {
			this.props.onSetMobileView(true);
			this.props.onToggleSideBar(false);
		}else {
			this.props.onSetMobileView(false);
			this.props.onToggleSideBar(true);
		}
	}
	handleHomePage = (e) => {
		e.preventDefault();
		this.props.onMainView();
	}
	handleMessageEvent = (e) =>{
		if(typeof e.data === "object"){
			if(typeof e.data.action !== 'undefined' &&
				e.data.action !== null){
				if(e.data.action == "open-errand"){
					let urlValid = isValidTelavoxUrl(e.origin);
					if(typeof e.data.params !== 'undefined' &&
						e.data.params !== null && urlValid == true){
						externalqueue.isPostMessage = true;
						if(typeof e.data.params.deepLink !== 'undefined' &&
							e.data.params.deepLink !== null &&
							e.data.params.deepLink !== ""){
								let encoded = e.data.params.deepLink;
								this.props.simpleLoadAndOpen(encoded, 0);
						}
					}
				} else {
					console.info("unhandled messsage action:", e.data.action);
				}
			}
		}
	}
	render() {
		const p = this.props, baseUi = p.baseUi;
		let layoutClass = "layout slim";
		let hide = false;
		let backNavHide = true;
		let showPopUpButton = p.ui.editSettings;
		let mobile = baseUi.showMobileView; //to-do : sub all var accodingly
		if(!this.props.baseUi.showSideBar) {
			layoutClass = "layout slim closed-sidebar";
		}

		if(baseUi.showMobileView){
			hide = true;
			if(baseUi.showSideBar) {
				hide = false;
				// layoutClass = "layout closed-sidebar";
			}
			if(baseUi.showErrandMobileView) {
				 backNavHide = false;
				if(baseUi.showSideBar){
					backNavHide = true;
				}
			}
		}else{
			hide = false;
			if(!baseUi.showSideBar) {
				layoutClass = "layout slim closed-sidebar";
			}
		}
		if(p.activateChat) {
			layoutClass += " opened-chat";
		}

		//BStable
		const options = {
			onDeleteRow: this.handleRemove,  // A hook for after droping rows.
			sizePerPage: getListSizeBasedOnHeight(),
			pageStartIndex: 1,
			paginationSize: 3,
			withFirstAndLast: true,
			hideSizePerPage: true,
			noDataText: I("Empty list"),
			paginationPanel: this.renderPaginationPanel,
			onRowClick: this.hanldleRowClick
		};

		//following old functions (pre V5)
		let settings = "";
		let preview = "";
		if(p.ui.editSettings == true) {
			settings = (
				<ExportInfoCtnr mobile={baseUi.showMobileView} />
			);
		}

		//Preview PopUp
		if(p.ui.showGeneratedExport == true || p.ui.showExportPreview == true) {
			preview = (
				<ExportPreviewV5
					showPopup={true}
					showDownload={p.ui.showGeneratedExport}
					showPreview={p.ui.showExportPreview}
					close={this.handlePreviewClose}
					mobile={mobile}
				/>
			);
		}
		let sidebarClass = "", logoWrapperStyle = {}, flexMenusStyle = {};
		if(baseUi.collapseSideBar){
			sidebarClass = "collapsed";
			logoWrapperStyle = {margin: "auto", textAlign: "center"};
			flexMenusStyle = {padding: "0px"};
		}
		const hideLogo = features['hide-cention-logo'];
		let customStyledAppHeader = { borderRadius: "initial"}
		if(hideLogo && baseUi.collapseSideBar) {
			customStyledAppHeader = { borderRadius: "initial", padding: "0px 15px"}
		}
		return (
			<div className="export-page">
				<div id="page-wrapper">
					<section className={"export "+layoutClass} data-app-layout>
						<div className={classNames("app-sidebar", sidebarClass)}>
							<div className="app-header" style={customStyledAppHeader}>
								{
									(hideLogo) &&
										<MenuCtnr visible={!baseUi.showErrandMobileView && !baseUi.collapseSideBar} customCurrentLabel={I("Export")} />
								}
								{
									(hideLogo) &&
										<ToggleSideBar visible={baseUi.showSideBar || baseUi.showMobileView}
										collapsed={baseUi.collapseSideBar}
										mobileMode={baseUi.showMobileView}
										onCollapse={this.handleSideBarCollapse}
										onClick={this.handleSideBarToggle} />
								}
								<div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
									<CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
										<img src={baseUi.collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt=""/>
									</CancellableSimpleLink>
								</div>
							</div>
							{/* alt App header */}
							<WorkflowInnerContainerCtnr className="app-inner-content" data-custom-scrollbar hidden={hide}>
								<div className="flex-menus-wrap" style={flexMenusStyle}>
									<div className="side-bar-main-menu" hidden={hideLogo}>
										{
											!baseUi.collapseSideBar &&
												<div style={{marginRight: "70px"}}><MenuCtnr visible={!baseUi.showErrandMobileView} customCurrentLabel={I("Export")}/></div>
										}
										<ToggleSideBar visible={baseUi.showSideBar || baseUi.showMobileView}
											showSideBar={baseUi.showSideBar}
											collapsed={baseUi.collapseSideBar}
											mobileMode={baseUi.showMobileView}
											id={"QA_toggle_sidebar_top"}
											onCollapse={this.handleSideBarCollapse}
											onClick={this.handleSideBarToggle} />
									</div>
									{
										!baseUi.collapseSideBar && features['sip-enabled'] &&
										<ul className="sidebar-menu search">
											<li className={"current"}><a data-qa-id="export_menu_errand" onClick={() => this.props.onChangePage(EXPORT_LINK)} data-value="Errands">{I('Errands')}</a></li>
											<li><a data-qa-id="export_menu_calls" onClick={() => this.props.onChangePage(EXPORTS_CALL_LINK)} data-value="Calls detail record">{I('Calls detail record')}</a></li>
										</ul>
									}
									{
										baseUi.collapseSideBar &&
											<IconizeSideBarMenuCtnr onChangePage={this.props.onChangeView} currentPage={MM_EXPORT} showWorkflowMenu={true} />
									}
								</div>
								<SidebarSettingsCtnr />
							</WorkflowInnerContainerCtnr>
						</div>
						<div className="app-content width-100 export">
							<div className="app-header" style={customStyledAppHeader}>
								<ul className="conversations-nav">
									<ToggleSideBar
										data-qa-id={"toggle-sidebar-search"}
										visible={!baseUi.showSideBar || baseUi.collapseSideBar}
										wrapped={true}
										collapsed={baseUi.collapseSideBar}
										onCollapse={this.handleSideBarCollapse}
										onClick={this.handleSideBarToggle} />
								</ul>
								{ (baseUi.collapseSideBar || !baseUi.showSideBar) && <WorkflowBreadcrumbsCtnr /> }
							</div>
							<WorkflowInnerContainerCtnr className="app-inner-content">
								<div className="exportv5-wrapper">
									{/* preview here? */}
									{preview}
									<div className="export-heading">
										<h2 className="export-heading-title">{I("Export | List")}</h2>
										<div className="export-icons left">
											{!mobile ?
												<div>
													<TableIconicButton type="button"
														className="btn-yellow"
														iconClass={"fas fa-plus-square"}
														title={I("Create new")}
														label={I("Create new")}
														onClick={this.createNew}/>
													<TableIconicButton type="button"
														hide={showPopUpButton ? false : true}
														className="btn-yellow"
														iconClass={"fas fa-external-link-alt"}
														title={I("Open form in its own layer")}
														onClick={() => this.handleTogglePopUp(true)}/>
												</div>
												:
												<TableIconicButton type="button"
													className="btn-yellow create-new"
													iconClass={"fas fa-plus"}
													title={I("Pop out form")}
													onClick={this.handleMobileCreateForm}
												/>
											}
										</div>
									</div>
									<div className="export-content-wrapper">
										<div className="export-table">
											<BootstrapTable ref='table'
												containerClass="tk-whole-table-container"
												tableContainerClass="tk-tableContainer"
												hover={true}
												bordered={false}
												data={this.props.tableList}
												// insertRow={true}
												// deleteRow={true}
												// selectRow={selectRowProp}
												options={options}
												tableHeaderClass={TABLE_BASE_CLASSNAME + " c3-export-table-v5"}
												tableBodyClass={TABLE_BASE_CLASSNAME + " c3-export-table-v5"}
												headerContainerClass='export-table-header-container'
												bodyContainerClass='export-table-body-container'
												pagination={true}
												version='4'
											>
												<TableHeaderColumn dataField='id' isKey={true} hidden={true} dataSort={true}>{I("Id")}</TableHeaderColumn>
												<TableHeaderColumn width={'120px'} dataField='name' dataSort={true}>{I("Name")}</TableHeaderColumn>
												<TableHeaderColumn width={'120px'} dataField='createdBy' dataSort={true}>{I("Created by")}</TableHeaderColumn>
												<TableHeaderColumn width={'60px'} dataField='privacy' dataSort={true}>{I("Personal")}</TableHeaderColumn>
												<TableHeaderColumn width={'120px'} dataField='action'>{I("Run")}</TableHeaderColumn>
												<TableHeaderColumn width={'60px'} dataField='activate' dataFormat={this.renderCheckBox}>{I("Active")}</TableHeaderColumn>
												<TableHeaderColumn width={'40px'} dataFormat={this.renderDeleteButton}></TableHeaderColumn>
											</BootstrapTable>
										</div>
										<div className="export-edit">
											{!mobile && settings}
											<div className={classNames("edit export popup",{"open":p.ui.showPopup})}>
												<div className="export-inner popup-inner create-inner">
													{settings}
													<div data-qa-id="edit-export-close" className="popup-close" onClick={() => this.handleTogglePopUp(false)}>
														<i className="icon-times"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</WorkflowInnerContainerCtnr>
						</div>
					</section>
				</div>
			</div>
		)
	}
}

const ExportCtnr = withExport(Exportv5);

export default ExportCtnr;

//Preview Download
export class ExportPreviewV5 extends React.Component {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			togglePreview: false
		}
	}
	handleClose() {
		this.props.onClose(false);
	}
	render() {
		const { showPopup, showDownload, showPreview , close, mobile } = this.props;

		let cbClass = "export preview popup", title = I("Export Preview");
		if(showPopup) {
			cbClass = "export preview popup open";
		}
		if(showDownload) {
			title =  I("Generated Export | List");
		}
		return(
			<div className={cbClass}>
				<div className="report-inner popup-inner create-inner">
					<div className="report-preview-box">
						<div className="popup-tabs">
							<span className={"toggle-tabs"} >
								{title}
							</span>
						</div>
						{showDownload && <GeneratedExportCtnr mobile={mobile}/>}
						{showPreview && <ExportPreviewTableCtnr/>}
						<div data-qa-id="create-report-close" className="popup-close" onClick={close}>
							<i className="icon-times"></i>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class GeneratedPreviewTable extends React.Component {
	constructor(props){
		super(props);
	}

	handleDownload = id => {
		this.props.onDownload(id)
	}

	handleRemoveRow = (id, name) => {
		let confirmDelMsg = I('Are you sure you want to delete this row from the generated export? {EXPORTNAME}').replace('{EXPORTNAME}', name)
		if (confirm(confirmDelMsg)) {
			this.props.onRemove(id);
		}
	}

	renderDownloadButton = (cell, row, enumObject, index) => {
		return <div className="table-btns">
				<TableIconicButton type="button" className="btn-blue" iconClass={"fas fa-file-download"} title={I("Download")} onClick={e => this.handleDownload(row.id)}/>
				<TableIconicButton type="button" className="btn-red" iconClass={"far fa-trash-alt"} title={I("Delete")} onClick={e => this.handleRemoveRow(row.id, row.exportName)}/>
			</div>
	}

	renderPagination = (props) => {
		return (
			<div className="wf-pagination">
				<div className="wf-pagination-page">
				{  props.totalPages > 1 && props.components.pageList }
				</div>
				<div className="wf-pagination-current">
					{
						(this.props.list && this.props.list.length > 0) &&
							I('Showing {CURRENT_PAGE} of {TOTAL_PAGES}')
								.replace('{CURRENT_PAGE}', props.currPage)
								.replace('{TOTAL_PAGES}', props.totalPages)
					}
				</div>
			</div>
		);
	}

	render() {
		const bodyHeight = 0.8 * window.innerHeight, rowHeight = 40;
		const bodyOffsets = (30 + 55 + 50 + 60) //heading + thead + paginationFooter + paddings
		const options = {
			onDeleteRow: this.handleRemoveRow,
			sizePerPage: calculateRowsToFitInContainer(bodyHeight, rowHeight, bodyOffsets),
			pageStartIndex: 1,
			paginationSize: 3,
			withFirstAndLast: true,
			hideSizePerPage: true,
			noDataText: I("Empty list"),
			paginationPanel: this.renderPagination
		};

		const { mobile } = this.props;
		return (
			<div className="export-table download">
				<BootstrapTable
					hover={true}
					data={this.props.list}
					options={options}
					tableHeaderClass={TABLE_BASE_CLASSNAME}
					tableBodyClass={TABLE_BASE_CLASSNAME}
					headerContainerClass='generate-header-container'
					bodyContainerClass='generate-body-container'
					pagination={true}
					version='4'
					bordered={false}
				>
					<TableHeaderColumn dataField='id' isKey={true} hidden={true} dataSort={true}>{I("Id")}</TableHeaderColumn>
					<TableHeaderColumn width={mobile ?'100px':'auto'}dataField='exportName' dataSort={true}>{I("Name")}</TableHeaderColumn>
					<TableHeaderColumn width={mobile ?'100px':'auto'}dataField='startTime' dataSort={true}>{I("Started")}</TableHeaderColumn>
					<TableHeaderColumn width={mobile ?'100px':'auto'}dataField='finishTime' dataSort={true}>{I("Finished")}</TableHeaderColumn>
					<TableHeaderColumn width={mobile ?'60px':'auto'}dataField='size'>{I("Size")}</TableHeaderColumn>
					{/* <TableHeaderColumn dataField='activate' dataFormat={this.renderCheckBox} width={'80px'}>{I("Active")}</TableHeaderColumn>
					<TableHeaderColumn dataFormat={this.renderEditButton}></TableHeaderColumn> */}
					<TableHeaderColumn dataFormat={this.renderDownloadButton}></TableHeaderColumn>
				</BootstrapTable>
			</div>
		)
	}
}

export const GeneratedExportCtnr = withGeneratedExport(GeneratedPreviewTable)

class ExportPreviewTable extends React.Component {
	constructor(props){
		super(props);
	}

	handleDownload = id => {
		this.props.onDownload(id)
	}

	renderPagination = (props) => {
		return (
			<div className="wf-pagination">
				<div className="wf-pagination-page">
				{  props.totalPages > 1 && props.components.pageList }
				</div>
				<div className="wf-pagination-current">
					{
						(this.props.list && this.props.list.length > 0) &&
							I('Showing {CURRENT_PAGE} of {TOTAL_PAGES}')
								.replace('{CURRENT_PAGE}', props.currPage)
								.replace('{TOTAL_PAGES}', props.totalPages)
					}
				</div>
			</div>
		);
	}

	addIdToList = (list) => {
		for (i = 0; i < list.length; i++){
			list[i].id = i;
		}
		return list;
	}

	checkForId = (list) => {
		for (i = 0; i < list.length; i++){
			if(list[i].id){
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	render() {
		const options = {
			onDeleteRow: this.handleRemoveRow,
			sizePerPage: getListSizeBasedOnHeight(),
			// sizePerPage:5,
			pageStartIndex: 1,
			paginationSize: 3,
			withFirstAndLast: true,
			hideSizePerPage: true,
			noDataText: I("Empty list"),
			paginationPanel: this.renderPagination
		};
		let column = [];
		let itemList = this.props.list;

		if(this.props.column != null){
			for(let i = 0; i < this.props.column.length; i++){
				column.push({
					'header': I("{ITEM}").replace("{ITEM}", this.props.column[i]),
					'key': this.props.column[i]
				})
			}
		}
		if(this.checkForId(this.props.list) == false){
			itemList = this.addIdToList(this.props.list);
		}
		return (
			<div className="export-table preview">
				<br/>
				<p>
					{I("The above preview is generated without any advanced errand filtering and may contain duplicates that will not be in the final export. This is to ensure that previews are generated quickly.")}
				</p>
				<BootstrapTable
					hover={true}
					data={itemList}
					options={options}
					tableHeaderClass={TABLE_BASE_CLASSNAME}
					tableBodyClass={TABLE_BASE_CLASSNAME}
					pagination={true}
					version='4'
					keyField='id'
					bordered={false}
				>
					{column.map(column => <TableHeaderColumn key={column.key} dataField={column.header}>{column.header}</TableHeaderColumn>)}
				</BootstrapTable>
			</div>
		)
	}
}

export const ExportPreviewTableCtnr = withMapExportPreview(ExportPreviewTable)
